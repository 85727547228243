import React from 'react';
import T from 'i18n-react'
import {Field, Control, Input, Icon, Button } from 'bloomer';
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import alertify from 'alertify.js'

import clipput from '../lib/clipput'

class WalletDisplay extends React.Component {
	copyWallet = (e) => {
		e.preventDefault();
		clipput(this.props.value)
		alertify.success(T.translate("global.labels.clipboard.copied"))
	}

	render() {
		return (
			<Field hasAddons isFullWidth hasTextAlign="centered">
			    <Control isExpanded>
			        <Input isReadonly hasTextAlign="centered" disabled={!this.props.value} value={this.props.value} className='cryptowallet-display' />
			    </Control>               
			    <Control>
			    	<Button isColor="primary" onClick={this.copyWallet} isStatic={!this.props.value} style={{ height: '48px' }}>
			    		<span className='icon is-right'>
				            <FontAwesomeIcon style={{cursor: 'pointer'}} color="white" icon={["fal", "clone"]} />
				        </span>
			    	</Button>
			    </Control>
			</Field>
		)
	}
}

export default WalletDisplay;