import React from 'react'

import '../assets/styles/swicon.css'

export function SwError(props) {
    return (
    <div class="swicon">
        <div class="f-modal-icon f-modal-error animate">
            <span class="f-modal-x-mark">
                <span class="f-modal-line f-modal-left animateXLeft"></span>
                <span class="f-modal-line f-modal-right animateXRight"></span>
            </span>
            <div class="f-modal-placeholder"></div>
            <div class="f-modal-fix"></div>
        </div>
    </div>
    )
}

export function SwSuccess(props) {
    return (
    <div class="swicon">
        <div class="f-modal-icon f-modal-success animate">
            <span class="f-modal-line f-modal-tip animateSuccessTip"></span>
            <span class="f-modal-line f-modal-long animateSuccessLong"></span>
            <div class="f-modal-placeholder"></div>
            <div class="f-modal-fix"></div>
        </div>
    </div>
    )
}



export function SwWarning(props) {
    return (
    <div class="swicon">
        <div class="f-modal-icon f-modal-warning scaleWarning">
            <span class="f-modal-body pulseWarningIns"></span>
            <span class="f-modal-dot pulseWarningIns"></span>
        </div>
    </div>
    )
}
