import React, { Component, Fragment } from 'react';
import T from 'i18n-react'
import { Link } from 'react-router-dom'
import {
    Section, Container,
    Columns, Column,
    Card, CardContent,
    Button, Input,
    Tab, Tabs, TabList, TabLink, Icon,
    Tag,
    Title, Subtitle,
    Modal, ModalBackground, ModalCard, ModalCardHeader, ModalCardTitle, Delete, ModalCardBody, ModalCardFooter
} from "bloomer";
import moment from 'moment';
import currencyjs from 'currency.js'
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { capitalCase } from 'change-case'

import cap, { Redir } from '../lib/capsdk'
import { getExplorerLink, getExplorerWalletLink } from '../helpers/string'

import TopNavBar from "../components/TopNavBar";
import Footer from '../components/Footer';
import HeaderTitle from "../components/HeaderTitle";
import Avatar from '../components/Avatar';
import { SwSuccess } from '../components/SWIcon'

import disputeIcon from '../assets/images/SVG/dispute.svg'

import '../assets/styles/page-transaction.css';
import "../assets/styles/page-index.css";
import "../assets/styles/payments.css";

class TransactionDetails extends Component {

    state = {
        txData: {},
        reverseQuote: false
    }

    componentWillMount = () => {
        cap.requestA('transactions.getTransactionDetails', {
            txid: this.props.match.params.id
        })
        .then(txData => {
            let reverseQuote = txData.ratio && (1/txData.ratio > txData.ratio)
            this.setState({ txData, reverseQuote })
        })
        .catch(e => {
            console.error(e)
            this.setState({
                redir: '/not-found'
            })
        })
    }

    report = () => {
        cap.requestA('transactions.openDispute', {
            tx: this.props.match.params.id,
            msg: this.state.disputeTxt
        })
        this.setState({
            disputeSent: true
        })
    }

    render() {
        return (    
            <main id="page-transaction">
                <Redir to={this.state.redir} />

                <Modal isActive={window.location.search === '?report'} id="open-dispute-modal">
                    <Link style={{ cursor: 'default' }} to={window.location.pathname}><ModalBackground className='animated fadeIn' /></Link>
                    <ModalCard className="animated fadeInDown">
                        <ModalCardHeader>
                            <ModalCardTitle>{ T.translate("pages.transactions.dispute") }</ModalCardTitle>
                            <Link to={window.location.pathname}><Delete /></Link>
                        </ModalCardHeader>

                        { this.state.disputeSent ?
                            <ModalCardBody className="scroll-if-needed">
                                <SwSuccess />
                                <center>
                                    <h2 className="title has-text-weight-light">
                                        { T.translate("pages.transactions.disputeWnd.successTitle") } 
                                    </h2>
                                    <small className="subtitle  is-5 has-text-weight-light">
                                        { T.translate("pages.transactions.disputeWnd.successText") }
                                    </small>
                                </center>
                            </ModalCardBody>
                            :
                            <React.Fragment>
                                <ModalCardBody className="scroll-if-needed">
                                    <Columns>
                                        <Column isSize={2}>
                                            <img src={disputeIcon} />
                                        </Column>
                                        <Column isSize={10}>
                                            <h2 className="title has-text-weight-light">
                                                { T.translate("pages.transactions.disputeWnd.intro") } 
                                            </h2>
                                            <p>
                                                { T.translate("pages.transactions.disputeWnd.introText") }
                                            </p>
                                        </Column>
                                    </Columns>
                                    <small className="subtitle  is-5 has-text-weight-light">{ T.translate("pages.transactions.disputeWnd.txt") }</small>
                                    <br/>
                                    <div className='field'>
                                        <textarea className='textarea' value={this.state.disputeTxt} onChange={e => this.setState({ disputeTxt: e.target.value })} />
                                    </div>
                                </ModalCardBody>
                                <ModalCardFooter>
                                    <Button isColor='primary' onClick={this.report}>{ T.translate("pages.transactions.disputeWnd.ok") }</Button>
                                    <Link to={window.location.pathname}><Button isColor='secondary'>{ T.translate("global.labels.cancel") }</Button></Link>
                                </ModalCardFooter>
                            </React.Fragment>
                        }
                    </ModalCard>
                </Modal>

                <TopNavBar />
                <HeaderTitle useCustomIcon="payments" title={ T.translate("pages.transaction.title") } isColor="black" />
                
                <Section>
                    <Container className="is-folder">
                        <Card>
                            <CardContent>
                                <Columns isMultiline isCentered isVCentered>
                                    <Column isSize={6}>
                                        <p className="has-text-centered is-spaced">
                                            <Title>{ this.state.txData.history && T.translate('global.movements.'+this.state.txData.history)}</Title>
                                            <Tag isColor="light" isSize='medium'>{ T.translate("pages.transactions.transferIDLabel") } { this.state.txData.txid }</Tag>
                                            <br/>
                                            <br/>
                                            {moment(this.state.txData.created_at).format('LLLL')}
                                            <br/>
                                            <br/>

                                            { this.state.txData.amount_origin && this.state.txData.to_wallet && this.state.txData.to_wallet.currency && this.state.txData.to_wallet.currency.id && this.state.txData.to_wallet.currency.id != this.state.txData.currency.id ?
                                                <Fragment>
                                                    <Title>{ currencyjs(this.state.txData.amount_origin, this.state.txData.to_wallet.currency).format(true) }</Title>
                                                    <br/>
                                                    <Subtitle>{ currencyjs(this.state.txData.amount, {...this.state.txData.currency, formatWithSymbol: true }).format() }</Subtitle>
                                                </Fragment>
                                                :
                                                <Title>{ currencyjs(this.state.txData.amount, {...this.state.txData.currency, formatWithSymbol: true }).format() }</Title>
                                            }

                                            { this.state.txData.ratio &&
                                                <p class="has-text-grey-light" style={{ marginBottom: '10px' }}>
                                                  { T.translate("modals.moneyExchange.ratio") }{" "}
                                                  { currencyjs(this.state.reverseQuote ? 1/this.state.txData.ratio : 1, this.state.txData.history.endsWith('_sent') ? this.state.txData.to_wallet.currency : this.state.txData.from_wallet.currency).format(true) }
                                                  {" = "}
                                                  { currencyjs(this.state.reverseQuote ? 1 : this.state.txData.ratio, this.state.txData.history.endsWith('_sent') ? this.state.txData.from_wallet.currency : this.state.txData.to_wallet.currency).format(true) }
                                                  {" "}
                                                  <a href="javascript:void(0)" onClick={() => this.setState({ reverseQuote: !this.state.reverseQuote })}>
                                                    <FontAwesomeIcon icon={["fal", "sort"]} rotation={90} />
                                                  </a>
                                                </p>
                                            }

                                            { this.state.txData.ipfs &&
                                                <Button href={"https://ipfs.io/ipfs/"+this.state.txData.ipfs} target="_blank" className="is-rounded" isColor="primary" style={{ marginRight: '10px' }}>
                                                    <FontAwesomeIcon icon={["fal", "cube"]} fixedWidth style={{ marginRight: '9px' }} />
                                                    { T.translate("pages.transactions.receiptIPFS") }
                                                </Button>
                                            }
                                            <Link to={this.props.location.pathname+".pdf"} className="button is-primary is-rounded">
                                                <FontAwesomeIcon icon={["fal", "file-pdf"]} fixedWidth style={{ marginRight: '9px' }} />
                                                { T.translate("pages.transactions.receiptPDF") }
                                            </Link>
                                            <Link to={this.props.location.pathname+"?report"} className="button is-danger is-rounded" style={{ marginLeft: '10px' }}>
                                                <FontAwesomeIcon icon={["fal", "flag"]} fixedWidth style={{ marginRight: '9px' }} />
                                                { T.translate("pages.transactions.dispute") }
                                            </Link>
                                        </p>
                                    </Column>

                                    <Column isSize={12}>
                                        <Columns>
                                            <Column isSize={4.5}>
                                                <div className={"wallet has-vcentered is-"+(this.state.txData.id && this.state.txData.from_wallet.is_crypto ? 'global' : (this.state.txData.id && this.state.txData.from_wallet && this.state.txData.from_wallet.owner && this.state.txData.from_wallet.owner.name ? 'personal' : 'anonymous'))}>
                                                    <div className="inner has-vcentered has-text-gray">
                                                        <Columns>
                                                            <Column className="is-narrow">
                                                                <Avatar isSize="64x64" url={this.state.txData.id && this.state.txData.from_wallet.owner && this.state.txData.from_wallet.owner.avatar} />
                                                            </Column>
                                                            <Column>
                                                                <b>
                                                                    { this.state.txData.from_wallet && this.state.txData.from_wallet.owner && this.state.txData.from_wallet.owner.name }
                                                                    { this.state.txData.from_wallet && this.state.txData.from_wallet.is_crypto && this.state.txData.from_wallet.currency.name }
                                                                </b>
                                                                <br/>
                                                                <span style={{wordBreak: 'break-word'}}>
                                                                    { this.state.txData.from_wallet && !this.state.txData.from_wallet.is_crypto &&
                                                                        <Link to={"/wallet/"+this.state.txData.from_wallet.wallet_address}>
                                                                            { this.state.txData.from_wallet.wallet_address }
                                                                        </Link>
                                                                    }

                                                                    { this.state.txData.from_wallet && this.state.txData.from_wallet.is_crypto &&
                                                                        <a target="_blank" href={this.state.txData.args.txid ? getExplorerLink(this.state.txData.from_wallet.currency.iso_code, this.state.txData.args.txid) : getExplorerWalletLink(this.state.txData.from_wallet.currency.iso_code, this.state.txData.from_wallet.wallet_address)}>
                                                                            { this.state.txData.from_wallet.wallet_address || [T.translate("pages.transactions.viewCryptoTx")+" ", <FontAwesomeIcon icon={["fal", "external-link"]} />] }
                                                                        </a>
                                                                    }
                                                                </span>
                                                            </Column>
                                                        </Columns>
                                                    </div>
                                                </div>
                                            </Column>

                                            <Column isSize={3}>
                                                { this.state.txData.currency &&
                                                    <div className={"transaction-arrow is-"+this.state.txData.currency.iso_code.toLowerCase()}></div>
                                                }
                                            </Column>

                                            <Column isSize={4.5}>
                                                <div className={"wallet has-vcentered is-"+(this.state.txData.id && this.state.txData.to_wallet.is_crypto ? 'global' : (this.state.txData.id && this.state.txData.from_wallet && this.state.txData.from_wallet.owner && this.state.txData.from_wallet.owner.name ? 'personal' : 'anonymous'))}>
                                                    <div className="inner has-vcentered has-text-gray">
                                                        <Columns>
                                                            <Column className="is-narrow">
                                                                <Avatar isSize="64x64" url={this.state.txData.id && this.state.txData.to_wallet.owner && this.state.txData.to_wallet.owner.avatar} />
                                                            </Column>
                                                            <Column>
                                                                <b>
                                                                    { this.state.txData.to_wallet && this.state.txData.to_wallet.owner && this.state.txData.to_wallet.owner.name }
                                                                    <i>{ this.state.txData.to_wallet && this.state.txData.to_wallet.is_crypto && this.state.txData.to_wallet.currency.name }</i>
                                                                </b>
                                                                <br/>
                                                                <span style={{wordBreak: 'break-word'}}>
                                                                    { this.state.txData.to_wallet && !this.state.txData.to_wallet.is_crypto &&
                                                                        <Link to={"/wallet/"+this.state.txData.to_wallet.wallet_address}>
                                                                            { this.state.txData.to_wallet.wallet_address }
                                                                        </Link>
                                                                    }

                                                                    { this.state.txData.to_wallet && this.state.txData.to_wallet.is_crypto &&
                                                                        <a target="_blank" href={this.state.txData.args.txid ? getExplorerLink(this.state.txData.to_wallet.currency.iso_code, this.state.txData.args.txid) : getExplorerWalletLink(this.state.txData.to_wallet.currency.iso_code, this.state.txData.to_wallet.wallet_address)}>
                                                                            { this.state.txData.to_wallet.wallet_address || [T.translate("pages.transactions.viewCryptoTx")+" ", <FontAwesomeIcon icon={["fal", "external-link"]} />] }
                                                                        </a>
                                                                    }

                                                                    { ['ted_withdraw', 'fiat_withdraw'].includes(this.state.txData.history) && this.state.txData.to_wallet.bank_account && this.state.txData.to_wallet.bank_account.type === 'ted' &&
                                                                        <React.Fragment>
                                                                            <b>{ this.state.txData.to_wallet.bank_account.holder}</b> ({ this.state.txData.to_wallet.bank_account.doc})
                                                                            <br />
                                                                            { this.state.txData.to_wallet.bank_account.bank.Code} { this.state.txData.to_wallet.bank_account.bank.Name}
                                                                            <br/>
                                                                            { this.state.txData.to_wallet.bank_account.agency} / { this.state.txData.to_wallet.bank_account.account}
                                                                            <br/>
                                                                            { this.state.txData.to_wallet.bank_account.type.toUpperCase()} / { this.state.txData.to_wallet.bank_account.savings ? "Conta poupança" : "Conta corrente"}
                                                                        </React.Fragment>
                                                                    }

                                                                    { ['sepa_withdraw', 'fiat_withdraw'].includes(this.state.txData.history) && this.state.txData.to_wallet.bank_account.type === 'sepa' &&
                                                                        <React.Fragment>
                                                                            <b>{ this.state.txData.to_wallet.bank_account.holderName}</b>
                                                                            <br/>
                                                                            <span className='tooltip' data-tooltip={this.state.txData.to_wallet.bank_account.bankAddress}>{ this.state.txData.to_wallet.bank_account.bankName} ({ this.state.txData.to_wallet.bank_account.type.toUpperCase()})</span>
                                                                            <br/>
                                                                            { this.state.txData.to_wallet.bank_account.swift} / { this.state.txData.to_wallet.bank_account.iban}
                                                                        </React.Fragment>
                                                                    }

                                                                    { ['boleto_withdraw', 'fiat_withdraw'].includes(this.state.txData.history) && this.state.txData.to_wallet.bank_account.type === 'boleto' &&
                                                                        <React.Fragment>
                                                                            <b>{ T.translate("modals.withdrawFunds.slip") }</b>
                                                                            <br/>
                                                                            <span className='tooltip' data-tooltip={ this.state.txData.to_wallet.bank_account.linhaBoleto}>
                                                                                { this.state.txData.to_wallet.bank_account.tipoBoleto === 'BANCO' ? this.state.txData.to_wallet.bank_account.bank : capitalCase(this.state.txData.to_wallet.bank_account.tipoBoleto || "") }
                                                                            </span>
                                                                            <br/>
                                                                        </React.Fragment>
                                                                    }
                                                                </span>
                                                            </Column>
                                                        </Columns>
                                                    </div>
                                                </div>
                                            </Column>

                                        </Columns>
                                        { this.state.txData.comments &&
                                            <Columns>
                                                <Column isSize={8} isOffset={2}>
                                                    <div class="notification">
                                                        <FontAwesomeIcon icon={["fal", "comment-lines"]} fixedWidth style={{ marginRight: '9px' }} /> {this.state.txData.comments}
                                                    </div>
                                                </Column>
                                            </Columns>
                                        }
                                        { this.state.txData.args && this.state.txData.args.invoiceData && this.state.txData.args.invoiceData.description &&
                                            <Columns>
                                                <Column isSize={8} isOffset={2}>
                                                    <div class="notification">
                                                        <FontAwesomeIcon icon={["fal", "comment-lines"]} fixedWidth style={{ marginRight: '9px' }} />{" "}
                                                        { this.state.txData.args.invoiceData.sender && this.state.txData.args.invoiceData.sender.name && <b>{this.state.txData.args.invoiceData.sender.name}: </b>}
                                                        {this.state.txData.args.invoiceData.description}
                                                    </div>
                                                </Column>
                                            </Columns>
                                        }
                                    </Column>

                                
                                </Columns>
                            </CardContent>
                        </Card>
                        
                    </Container>
                    </Section>
                <Footer />
            </main>
        );
    }
}

export default TransactionDetails;