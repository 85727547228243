import React from "react"
import T from "i18n-react"
import { Link } from 'react-router-dom'
import { Section, Container, Card, CardContent, Columns, Column, Hero, HeroHeader, HeroBody, HeroFooter, Box, Title, Subtitle, Button } from 'bloomer'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import TopNavBar from "../components/TopNavBar";
import Footer from "../components/Footer";
import { AskPin, DefPinProc } from '../components/PinInput'
import LoadingAnim from '../components/LoadingAnim'
import Avatar from '../components/Avatar'

import cap, { RequireLogin } from '../lib/capsdk'

import '../assets/styles/page-api.css'

const internalServices = {
	cappay: 'https://cappay.capitual.com/returnlogin?sess_key=' + cap.sess_key
}

class ServiceLogin extends React.Component {
	state = {
		loading: false,
		understandRisks: false,
		appInfo: {},
		perms: []
	}

	componentWillMount = () => {
		// is this an internal (Capitual-owned) service login?
		let requestingService = this.props.match.params.service

		if (cap.sess_key && Object.keys(internalServices).includes(requestingService)) {
			// just pass current token, will not ask for permission
			window.location.href = internalServices[requestingService]
		}
	}

	componentDidMount = async() => {
		let requestingService = this.props.match.params.service
		if (Object.keys(internalServices).includes(requestingService)) return;

		let qs = new URLSearchParams(window.location.search)

		let perms = String(qs.get('perms')).split(",")

		let returnUrlParsed = qs.get('return_url').match(/^(?:([A-Za-z]+):)?\/{0,3}([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/)

		// ask API for app data
		try {
			let appInfo = await cap.requestA('apps.getAppDetails', {
				appid: requestingService,
				publicKey: qs.get('public')
			})

			appInfo.public_key = qs.get('public')

			// checks
			if (!appInfo.name)
				this.setState({ err: 'noName' })
			else if (!appInfo.author || !appInfo.author_email || !appInfo.author_phone)
				this.setState({ err: 'noAuthor' })
			else if (!appInfo.tos || !appInfo.pp)
				this.setState({ err: 'noLegal' })
			else if (!qs.get('perms'))
				this.setState({ err: 'noPerms' })
			else if ((!appInfo.domains.length || !appInfo.domains.includes(returnUrlParsed[2])) && returnUrlParsed[2] != 'localhost')
				this.setState({ err: 'noDomain' })
			else if (returnUrlParsed[1] !== "https")
				this.setState({ err: 'noSsl' })
			else
				this.setState({ appInfo, perms, returnUrlParsed })
		} catch (e) {
			this.setState({ err: 'noApp' })
		}
	}

	allowAccess = async() => {
		try {
			let pin = await AskPin(T.translate("pages.graph.pinTitle"))
			let sess_key = await cap.requestA('session.createApiKey', {
				pin,
				appid: this.state.appInfo.id,
				publicKey: this.state.appInfo.public_key,
				permissions: this.state.perms
			})

			let { returnUrlParsed } = this.state

			let returnQs = new URLSearchParams(returnUrlParsed[5])
			returnQs.set('sess_key', sess_key)
			returnUrlParsed[5] = returnQs.toString()

			window.location.href = 'https://' + returnUrlParsed[2] + (returnUrlParsed[3] ? ':' + returnUrlParsed[3] : '') + '/' + returnUrlParsed[4] + '?' + returnUrlParsed[5] + (returnUrlParsed[6] ? '#' + returnUrlParsed[6] : '')
		} catch (e) {
			if (e === 'InvalidPermission')
				this.setState({ err: 'invalidPerm' })
			else
				DefPinProc(e)
		}
	}

	render() {
		return (
			<React.Fragment>
				<RequireLogin />
				<LoadingAnim isActive={this.state.loading} />

				<main id="page-api">
					<Hero isFullHeight>
						<HeroHeader>
							<TopNavBar />
						</HeroHeader>
						<HeroBody>

							{ !this.state.err &&
							<Container>
								<Columns isCentered>
									<Column isSize={9}>
										<Box>
											<Columns isMultiline>
												<Column isSize={12} hasTextAlign='centered'>													
													<a href="https://support.capitual.com/support/tickets/new" className='report-link has-text-warning is-size-7'><FontAwesomeIcon icon={["fal", "exclamation-triangle"]} style={{ marginRight: '5px' }} />{ T.translate("pages.graph.report") }</a>
													<Avatar url={this.state.appInfo.logo} isSize={128} className='api-app-logo is-rounded' />
													<Title>{ this.state.appInfo.name }</Title>
													<Subtitle>{ T.translate("pages.graph.subtitle") }</Subtitle>
												</Column>
												<Column isSize={9}>
													<Columns isMultiline>
														<Column isSize={12}>
															<h3 className='is-size-4 has-text-grey'>{ T.translate("pages.graph.wants") }</h3>
														</Column>

														{ this.state.perms.map(item =>
															<Column className='perm-item' isSize={4}>{ T.translate("pages.graph.permissions." + item) }</Column>
														)}

													</Columns>

													<p>{ T.translate("pages.graph.legalWarning") } <a href={this.state.appInfo.tos} target="_blank" rel="nofollow">{ T.translate("pages.legal.tos") }</a> { T.translate("global.labels.and") } <a href={this.state.appInfo.privacyPolicy} target="_blank" rel="nofollow">{ T.translate("pages.legal.pp") }</a>.</p>
												</Column>
												<Column isSize={3}>
													<h3 className='is-size-4 has-text-grey'>{ T.translate("pages.graph.developer") }</h3>

													<blockquote className='blockquote'>
														<p>{ this.state.appInfo.author }</p>
														<p>{ this.state.appInfo.author_email }</p>
														<p>{ this.state.appInfo.author_phone }</p>
													</blockquote>

													<p className='is-size-7'>
														{ T.translate("pages.graph.warning") }
													</p>
													<p className='is-size-7'>
														<br/>
														<label>
															<input type="checkbox" onChange={e => this.setState({ understandRisks: e.target.checked })} /> { T.translate("pages.graph.accept") }
														</label>
													</p>

													<hr />

													<Button isFullWidth isColor='primary' disabled={!this.state.understandRisks} onClick={this.allowAccess}>{ T.translate("pages.graph.allowBtn") }</Button>
													<br/>
													<a href="https://www.capitual.com" className='button is-fullwidth is-danger'>{ T.translate("pages.graph.denyBtn") }</a>
												</Column>
											</Columns>
										</Box>
									</Column>
								</Columns>
							</Container>
							}

							{ this.state.err &&
							<p class="notification is-warning">
								{ T.translate("pages.graph.errors." + this.state.err) }
							</p>
							}
						</HeroBody>
						<HeroFooter>
							<Footer />
						</HeroFooter>
					</Hero>
				</main>
			</React.Fragment>
		)
	}
}

export default ServiceLogin