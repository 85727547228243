import React, { Component}  from 'react';
import T from 'i18n-react'

import Icon from '../assets/images/SVG/coin_empty.svg'
import btcIcon from '../assets/images/SVG/coin_btc.svg'
import brlIcon from '../assets/images/SVG/coin_brl.svg'
import ethIcon from '../assets/images/SVG/coin_eth.svg'
import dshIcon from '../assets/images/SVG/coin_dsh.svg'
import eurIcon from '../assets/images/SVG/coin_eur.svg'
import jpyIcon from '../assets/images/SVG/coin_jpy.svg'
import usdIcon from '../assets/images/SVG/coin_usd.svg'
import gbpIcon from '../assets/images/SVG/coin_gbp.svg'
import bchIcon from '../assets/images/SVG/coin_bch.svg'
import ltcIcon from '../assets/images/SVG/coin_ltc.svg'
import xauIcon from '../assets/images/SVG/coin_xau.svg'
import xagIcon from '../assets/images/SVG/coin_xag.svg'
import eusdtIcon from '../assets/images/SVG/coin_eusdt.svg'
import bnbIcon from '../assets/images/SVG/coin_bnb.svg'
import busdIcon from '../assets/images/SVG/coin_busd.svg'
import usdcIcon from '../assets/images/SVG/coin_usdc.svg'
import daiIcon from '../assets/images/SVG/coin_dai.svg'
import amplIcon from '../assets/images/SVG/coin_ampl.svg'
import erdIcon from '../assets/images/SVG/coin_erd.svg'
import adaIcon from '../assets/images/SVG/coin_ada.svg'
import linkIcon from '../assets/images/SVG/coin_link.svg'
import dogeIcon from '../assets/images/SVG/coin_doge.svg'
import dotIcon from '../assets/images/SVG/coin_dot.svg'
import uniIcon from '../assets/images/SVG/coin_uni.svg'
import xrpIcon from '../assets/images/SVG/coin_xrp.svg'

const Icons = { Icon, btcIcon, brlIcon, ethIcon, dshIcon, eurIcon, jpyIcon, usdIcon, gbpIcon, bchIcon, ltcIcon, xauIcon, xagIcon, eusdtIcon, bnbIcon, busdIcon, usdIcon, daiIcon, amplIcon, erdIcon, usdcIcon, adaIcon, linkIcon, dogeIcon, dotIcon, uniIcon, xrpIcon }

const CurrencyIcon = ({currency, width, ...rest}) => (
	<img src={Icons[currency.toLowerCase()+'Icon']} width={width || 60} alt={currency} {...rest} />
)

export default CurrencyIcon
export { Icons }