import cap from '../lib/capsdk'

let lastUpdated = 0, res

export default class {
  static all() {
  	if (!res || !lastUpdated || lastUpdated + 5 < (new Date()).getTime()/1000) {
    	res = cap.requestA('wallets.getUserWallets', { sess_key: cap.sess_key })
    	lastUpdated = (new Date()).getTime()/1000
  	}
    return res
  }

  static clean() {
  	res = null
  }
}
