import React from "react";
import T from 'i18n-react'
import { Link } from "react-router-dom";
import {
  Hero,
  HeroBody,
  HeroFooter,
  Container,
  Box,
  Columns,
  Column,
  Image,
  Field,
  Button
} from "bloomer";
import moment from 'moment-timezone'
import validator from 'validator'
import FloatLabelInput from "../components/FloatLabelInput";
import FloatLabelPassword from "../components/FloatLabelPassword";
import Footer from "../components/Footer";
import alertify from "alertify.js";
import ReCaptcha from "react-google-recaptcha";

import "../assets/styles/page-login.css";

import logo from "../assets/images/SVG/capitual-logo.svg";

import cap from "../lib/capsdk";

import { detectLanguage } from '../helpers/i18n'

const recaptchaconf = {
	"sitekey": "6Ld38GoUAAAAABhtBDT5vZqlZR4PwHc68-akSTcC"
}

class Login extends React.Component {
  state = {
    isLoading: false,
    email: "",
    password: "",
    otp_token: "",
    keep: false,
    requestedToken: false,
    isUsernameError: false,
    isPasswordError: false,
    isTokenError: false,
    caps: false
  };

  componentDidMount = () => {
    // clear cache
    cap.clear();

    let qs = new URLSearchParams(window.location.search);

    if (cap.sess_key && qs.get('continue'))
      this.props.history.push(qs.get("continue"))

    else if (!qs.has("continue"))
      window.location.href = 'https://www.capitual.com'
  };

  blurEmail = () => {
    if (this.state.email && validator.isEmail(String(this.state.email))) {
      cap.requestA('members.resendSignupEmail', { email: this.state.email, locale: detectLanguage() })
        .then(() => {
          alertify.okBtn("OK").alert(T.translate("pages.login.noPassword"))
        })
        .catch(() => {})
    }
  }

  doLogin = async e => {
    if (e) e.preventDefault();
    this.setState({ isLoading: true });

    try {
      let sess_key = await cap.requestA("members.login", {
        locale: detectLanguage(),
        login: this.state.email,
        password: this.state.password,
        otp_token: this.state.otp_token ? this.state.otp_token.replace(/[^0-9]/g, "") : null
      });
      this.setState({ isLoading: false });

      sessionStorage.removeItem("sess_key");
			sessionStorage.setItem("sess_key", sess_key);

      cap.requestA('members.profileset', {args: { locale: detectLanguage(), timezone: moment.tz.guess() }})

      let qs = new URLSearchParams(window.location.search)

      if (qs.has("continue") && qs.get("continue").startsWith("/"))
        this.props.history.push(qs.get("continue"));
      else
				window.location.href = 'https://www.capitual.com'
    } catch (e) {
      this.setState({ isLoading: false });
      switch (e) {
        case "InvalidUsername":
          this.setState({ isUsernameError: true });
          break;

        case "InvalidPassword":
          this.setState({ isPasswordError: true, password: "" });
          break;

        case "InvalidOTPToken":
          this.setState({ isTokenError: true });
          break;

        case "InvalidEmailToken":
          this.setState({ isTokenError: true });
          break;

        case "RequestedEmailToken":
          this.setState({
            requestedToken: "email",
            isUsernameError: false,
            isPasswordError: false
          });
          break;

        case "RequestedOTPToken":
          this.setState({
            requestedToken: "otp",
            isUsernameError: false,
            isPasswordError: false
          });
          break;

        case "UserIsDisabled":
          alertify.okBtn("Close").alert(
            T.translate("pages.login.lockedAccount")
          );
          break;
      }
    }
  };

  onChange = e => {
    //e.preventDefault();
    let { name, value, type } = e.target;

    let caps = e.getModifierState && e.getModifierState("CapsLock");

    if (type == "checkbox")
      this.setState({ [name]: !this.state[name] });
    else
      this.setState({ [name]: value, caps }, () => {
      if (name==='otp_token' && value.length === 6)
        this.doLogin()
    });
  };

  render() {
    return (
      <Hero className="page-login" isFullHeight>
        <div className="recaptcha-badge">
          <ReCaptcha
            sitekey={recaptchaconf.sitekey}
            onChange={val => this.setState({ recaptcha_result: val })}
            size="invisible"
            badge="inline"
          />
        </div>
        <HeroBody>
          <Container>
            <Columns isCentered>
              <Column isSize={4} isMarginless isPaddingless>
                <form onSubmit={this.doLogin}>
                  <Box>
                    <Columns isMultiline>
                      <Column isSize="auto">
                        <a href="https://www.capitual.com">
                          <Image
                            src={logo}
                            isSize="64x64"
                            hasTextAlign="centered"
                            style={{ margin: "0 auto" }}
                          />
                        </a>
                      </Column>
                      <br />

                      {!this.state.requestedToken && (
                        <React.Fragment>
                          <Column isSize={12}>
                            <FloatLabelInput
                              label={T.translate("pages.login.usernameInput")}
                              name="email"
                              type="email"
                              value={this.state.email}
                              onChange={this.onChange}
                              onBlur={this.blurEmail}
                              isColor={
                                this.state.isUsernameError
                                  ? "danger"
                                  : undefined
                              }
                              helpText={
                                this.state.isUsernameError &&
                                T.translate("pages.login.invalidUsername")
                              }
                              required
                              autoFocus
                            />
                          </Column>
                          <Column isSize={12}>
                            <FloatLabelPassword
                              label={ T.translate("pages.login.passwordInput") }
                              type="password"
                              name="password"
                              value={this.state.password}
                              onChange={this.onChange}
                              isColor={
                                this.state.isPasswordError
                                  ? "danger"
                                  : undefined
                              }
                              helpText={
                                this.state.isPasswordError &&
                                T.translate("pages.login.invalidPassword")
                              }
                              required
                            />
                          </Column>
                        </React.Fragment>
                      )}

                      {this.state.requestedToken == "otp" && (
                        <Column isSize={12}>
                          <FloatLabelInput
                            label={T.translate("pages.login.otp")}
                            type="password"
                            name="otp_token"
                            pattern="[0-9]*"
                            inputmode="numeric"
                            value={this.state.otp_token}
                            onChange={this.onChange}
                            isColor={
                              this.state.isTokenError ? "danger" : undefined
                            }
                            helpText={
                              this.state.isTokenError && T.translate("pages.login.otpError")
                            }
                            required
                            autoFocus
                          />
                        </Column>
                      )}

                      {this.state.requestedToken == "email" && (
                        <Column isSize={12}>
                          <FloatLabelInput
                            label={T.translate("pages.login.emailToken")}
                            type="password"
                            name="otp_token"
                            value={this.state.otp_token}
                            onChange={this.onChange}
                            isColor={
                              this.state.isTokenError ? "danger" : undefined
                            }
                            helpText={
                              this.state.isTokenError
                                ? T.translate("pages.login.emailTokenInvalid")
                                : T.translate("pages.login.emailTokenHelper")
                            }
                            required
                            autoFocus
                          />
                        </Column>
                      )}

                      {!this.state.requestedToken && (
                        <Column isSize={12}>
                          <Columns isGapless isMarginless>
                            <Column isPulled="center" hasTextAlign="center" className='is-hidden-mobile'>
                              <a href="javascript:void(0)" onClick={() => alertify.alert("Utilize o aplicativo do Capitual em seu smartphone para recuperar sua senha")}>{ T.translate("pages.login.forgotPwd") }</a>
                            </Column>
                          </Columns>
                        </Column>
                      )}

                      <Column isSize={12}>
                        <br className='is-hidden-tablet' />
                        <Button
                          isColor="primary"
                          isSize="large"
                          isFullWidth
                          isLoading={this.state.isLoading}
                          type="submit"
                        >
                          { T.translate("pages.login.loginBtn") }
                        </Button>

                        { this.state.requestedToken &&
                          <p className="has-text-centered">
                            <br/>
                            <a href="#" onClick={() => this.setState({ requestedToken: false, email: "", password: "" })}>{ T.translate("pages.login.goBack") }</a>
                          </p>
                        }
                      </Column>
                    </Columns>
                  </Box>
                </form>
              </Column>
              <Columns className='no-border is-mobile is-hidden-tablet'>
                <Column className='no-border'>
                    <center>
                        <a href="https://play.google.com/store/apps/details?id=com.capitual">
                            <img style={{ width: '150px' }} src={T.translate("pages.settings.kyc.mobile.badge.android")} />
                        </a>
                    </center>
                </Column>
                <Column className='no-border'>
                    <center>
                        <a href="javascript:void(0)">
                            <img style={{ width: '135px', paddingTop: '8px'}} src={T.translate("pages.settings.kyc.mobile.badge.ios")} />
                        </a>
                    </center>
                </Column>
            </Columns>
            </Columns>
          </Container>
        </HeroBody>
        <HeroFooter>
          <Footer />
        </HeroFooter>
      </Hero>
    );
  }
}

export default Login;
