function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = new Array();
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i == 0)
        costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue),
              costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0)
      costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

export function similarity(s1, s2) {
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

export function isSafePin(pin) {
  let aPin = pin.split(""),
    aIntervals = []
  for (let i = 0; i < aPin.length; i++) {
    for (let j = 0; j < i; j++) {
      if (aPin[j] === aPin[i]) return false
    }
    if (i > 0) aIntervals.push(aPin[i] - aPin[i - 1])
    if (i < aPin.length - 1) aIntervals.push(aPin[i] + aPin[i - 1])
  }
  for (let i = 0; i < aIntervals.length; i++) {
    for (let j = 0; j < i; j++) {
      if (aIntervals[j] === aIntervals[i]) return false
    }
  }
  return true
}

export function StringSelect(what) {
  return function(conditions, otherwise) {
    for (let key in conditions) {
      if (key == what)
        return conditions[key]
    }
    return otherwise
  }
}

export function getExplorerLink(currency, txid) {
  let testnet = window.location.host.includes("testnet") || process.env.NODE_ENV === 'development'
  currency = currency.toUpperCase()
  if (!testnet) {
    switch (currency) {
      case 'BTC':
      case 'LTC':
      case 'DSH':
        currency = currency === 'DSH' ? 'DASH' : currency
        return 'https://live.blockcypher.com/' + currency.toLowerCase() + '/tx/' + txid
      case 'DASH':
        return 'https://live.blockcypher.com/dash/tx/' + txid
      case 'ETH':
      case 'EUSDT':
      case 'BUSD':
      case 'UNI':
      case 'USDC':
      case 'DAI':
      case 'LINK':
        return 'https://etherscan.io/tx/'+txid
      case 'ADA':
        return 'https://explorer.cardano.org/en/transaction?id='+txid
      case 'DOGE':
        return 'https://dogechain.info/tx/'+txid
      case 'DOT':
        return 'https://polkascan.io/polkadot/transaction/'+txid
      case 'BNB':
        return 'https://bscscan.com/tx/'+txid
      case 'XRP':
        return 'https://xrpscan.com/tx/'+txid
      case 'BCH':
        return 'https://explorer.bitcoin.com/bch/tx/'+txid
      default:
        return null
    }
  } else {
    switch (currency) {
      case 'BTC':
        return 'https://live.blockcypher.com/btc-testnet/tx/' + txid
      case 'LTC':
        return 'https://testnet.litecore.io/tx/' + txid
      case 'DSH':
      case 'DASH':
        currency = currency === 'DSH' ? 'DASH' : currency
        return 'https://testnet-insight.dashevo.org/insight/tx/' + txid
      case 'ETH':
      case 'EUSDT':
      case 'BUSD':
      case 'UNI':
      case 'USDC':
      case 'DAI':
      case 'LINK':
        return 'https://ropsten.etherscan.io/tx/'+txid
      case 'ADA':
        return 'https://explorer.cardano-testnet.iohkdev.io/en/transaction?id='+txid
      case 'DOGE':
        return 'https://blockexplorer.one/doge/testnet/tx/'+txid
      case 'DOT':
        return 'https://rococo.subscan.io/extrinsic/'+txid
      case 'BNB':
        return 'https://testnet.bscscan.com/tx/'+txid
      case 'XRP':
        return 'https://blockexplorer.one/xrp/testnet/tx/'+txid
      case 'BCH':
        return 'https://explorer.bitcoin.com/tbch/tx/'+txid
      default:
        return null
    }
  }
}

export function getExplorerWalletLink(currency, addr) {
  let testnet = window.location.host.includes("testnet") || process.env.NODE_ENV === 'development'
  currency = currency.toUpperCase()
  if (!testnet) {
    switch (currency) {
      case 'BTC':
      case 'LTC':
      case 'DSH':
        currency = currency === 'DSH' ? 'DASH' : currency
        return 'https://live.blockcypher.com/' + currency.toLowerCase() + '/address/' + addr
      case 'DASH':
        return 'https://live.blockcypher.com/dash/address/' + addr
      case 'ETH':
      case 'EUSDT':
      case 'BUSD':
      case 'UNI':
      case 'USDC':
      case 'DAI':
      case 'LINK':
        return 'https://etherscan.io/address/'+addr
      case 'ADA':
        return 'https://explorer.cardano.org/en/address?address='+addr
      case 'DOGE':
        return 'https://dogechain.info/address/'+addr
      case 'DOT':
        return 'https://polkascan.io/polkadot/account/'+addr
      case 'BNB':
        return 'https://bscscan.com/address/'+addr
      case 'XRP':
        return 'https://xrpscan.com/account/'+addr
      case 'BCH':
        return 'https://explorer.bitcoin.com/bch/address/'+addr
      default:
        return null
    }
  } else {
    switch (currency) {
      case 'BTC':
        return 'https://live.blockcypher.com/btc-testnet/address/' + addr
      case 'LTC':
        return 'https://testnet.litecore.io/address/' + addr
      case 'DSH':
      case 'DASH':
        currency = currency === 'DSH' ? 'DASH' : currency
        return 'https://testnet-insight.dashevo.org/insight/address/' + addr
      case 'ETH':
      case 'EUSDT':
      case 'BUSD':
      case 'UNI':
      case 'USDC':
      case 'DAI':
      case 'LINK':
        return 'https://ropsten.etherscan.io/address/'+addr
      case 'ADA':
        return 'https://explorer.cardano-testnet.iohkdev.io/en/address?address='+addr
      case 'DOGE':
        return 'https://blockexplorer.one/doge/testnet/address/'+addr
      case 'DOT':
        return 'https://rococo.subscan.io/account/'+addr
      case 'BNB':
        return 'https://testnet.bscscan.com/address/'+addr
      case 'XRP':
        return 'https://blockexplorer.one/xrp/testnet/address/'+addr
      case 'BCH':
        return 'https://explorer.bitcoin.com/tbch/address/'+addr
      default:
        return null
    }
  }
}