import React, { Component, Fragment } from 'react'
import T from 'i18n-react'
import { Link } from 'react-router-dom'
import { Page, Text, View, Document, StyleSheet, Font, Image, Canvas, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { capitalCase } from 'change-case'

import TopNavBar from '../components/TopNavBar'
import cap, { RequireLogin } from "../lib/capsdk"
import LoadingAnim from '../components/LoadingAnim'

import moment from 'moment-timezone'
import currencyjs from 'currency.js'

Font.register({
	family: 'Poppins',
	src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
})

const styles = StyleSheet.create({
	page: {
		backgroundColor: '#ebebeb',
		paddingBottom: 40,
		paddingTop: 23,
		paddingHorizontal: 23
	},
	viewer: {
		width: '100vw',
		height: 'calc(100vh - 65px - 64px)',
		margin: '0'
	},
	body: {
		fontFamily: 'Helvetica',
		backgroundColor: 'white',
		borderRadius: 5,
		width: '100%',
		marginBottom: 20,
		paddingBottom: 20
	},
	h1: {
		textAlign: 'center',
		fontSize: 19,
		paddingTop: 10,
		paddingBottom: 20,
		color: '#2A3033'
	},
	h2: {
		textAlign: 'center',
		fontSize: 17,
		paddingBottom: 50,
		color: '#A1A1A1'
	},
	h3: {
		color: '#0BB4F5',
		textAlign: 'center',
		marginTop: 10,
		marginBottom: 5,
		letterSpacing: 3,
		textTransform: 'uppercase',
		fontSize: 14
	},
	h4: {
		color: '#A1A1A1',
		marginTop: 10,
		marginBottom: 5,
		letterSpacing: 3,
		textTransform: 'uppercase',
		fontSize: 14,
		textAlign: 'center'
	},
	logo: {
		width: 96,
		marginBottom: 20
	},
	canvas: {
		width: 50,
		height: 50,
		borderRadius: 8,
		backgroundColor: '#0BB4F5',
		left: (458/2)-50,
		top: -25
	},
	label: {
		color: '#A1A1A1',
		fontSize: 14,
		width: '20%',
		marginLeft: 14,
		display: 'inline-block',
	},
	item: {
		color: '#2A3033',
		fontSize: 15,
		width: '100%',
		textAlign: 'right',
		display: 'inline-block',
		top: -15,
		right: 15
	},
	longText: {
		color: '#2A3033',
		fontSize: 12,
		width: '100%',
		textAlign: 'right',
		display: 'block',
		top: 5,
		marginBottom: 5,
		right: 15
	},
	value: {
		borderRadius: 10,
		backgroundColor: '#F8F8F8',
		borderColor: '#F0F0F0',
		textAlign: 'center',
		margin: 15
	},
	valueLabel: {
		color: '#A1A1A1',
		marginTop: 10,
		marginBottom: 5,
		letterSpacing: 3,
		textTransform: 'uppercase',
		fontSize: 14
	},
	valueNumber: {
		color: '#0BB4F5',
		marginTop: 5,
		marginBottom: 10,
		fontSize: 20
	},
	valueNumber2: {
	    color: '#2A3033',
	    marginTop: 5,
	    marginBottom: 10,
	    fontSize: 15,
	  },
	hr: {
		width: 458-46,
		height: 1
	},
	blackCenter: {
		textAlign: 'center',
		fontStyle: 'light',
		fontSize: 15,
		marginVertical: 5,
		color: '#2A3033'
	},
	textCenter: {
		textAlign: 'center',
		color: '#2A3033',
		fontSize: 14,
		marginVertical: 4
	},
	comments: {
		fontSize: 11,
		margin: 15,
		fontStyle: 'italic',
	},
	footer: {
		color: 'gray',
		textAlign: 'center',
		fontSize: 10,
		marginTop: 5
	}
})

const Hr = () => <Canvas style={styles.hr} paint={painter => painter.strokeColor("#F0F0F0").moveTo(15, 0).lineTo(458-46-15, 0).stroke() } />

const drawIcon = painter => {
	painter.strokeColor("white")
	painter.moveTo(16, 22).lineTo(32, 22).quadraticCurveTo(32, 22, 28, 17).stroke()
	painter.moveTo(32, 26).lineTo(16, 26).quadraticCurveTo(16, 26, 22, 31).stroke()
}

class Receipt extends Component {

	state = {
		loading: true,
		reverseQuote: false
	}

	componentWillMount = () => {
        cap.requestA('transactions.getTransactionDetails', {
            txid: this.props.match.params.id
        })
        .then(txData => {
        	let reverseQuote = txData.ratio && (1/txData.ratio > txData.ratio)
            this.setState({ txData, reverseQuote }, async() => {
            	if (txData.history === 'ted_withdraw') {
            		this.setState({
            			intermediator: await cap.requestA('constants.bank', { country: 'br' }),
            			loading: false
            		})
            	} else if (txData.history === 'sepa_withdraw') {
            		this.setState({
            			intermediator: await cap.requestA('constants.bank', { country: 'eur' }),
            			loading: false
            		})
            	} else {
            		this.setState({ loading: false })
            	}
            })
        })
        .catch(e => {
            console.error(e)
            this.setState({
                redir: '/not-found'
            })
        })
    }

	PDFGenerator = () => !this.state.loading && (
		<Document
			title="Capitual"
			author="Capitual Ltd"
			creator="Capitual.com"
			producer="Capitual.com"
		>
			<Page
				size={458}
				style={styles.page}
			>
				<Image style={styles.logo} src="https://static.capitual.net/brand/capitual-horizontal-black.png" />

				<View style={styles.body}>

					<Canvas style={styles.canvas} paint={drawIcon} />

					<Text style={styles.h1}>{ ['ted_withdraw', 'sepa_withdraw', 'boleto_withdraw', 'fiat_withdraw'].includes(this.state.txData.history) ? T.translate("pdf.tx.title.bankTransfer") : T.translate("pdf.tx.title.transfer")}</Text>
					<Text style={styles.h2}>{ T.translate('global.movements.'+this.state.txData.history) }</Text>

					<Text style={styles.label}>{ T.translate("pdf.tx.protocol") }</Text>
					<Text style={styles.item}>{ this.state.txData.txid }</Text>

					<Text style={styles.label}>{ T.translate("pdf.tx.time") }</Text>
					<Text style={styles.item}>{ moment(this.state.txData.created_at).format('llll') }</Text>

					<View style={styles.value}>
						<Text style={styles.valueLabel}>{ T.translate("pdf.tx.value") }</Text>

						{ this.state.txData.amount_origin && this.state.txData.to_wallet && this.state.txData.to_wallet.currency && this.state.txData.to_wallet.currency.id && this.state.txData.to_wallet.currency.id != this.state.txData.currency.id ?
							<Fragment>
								<Text style={styles.valueNumber}>{ this.state.txData.to_wallet.currency.iso_code + " " + currencyjs(this.state.txData.amount_origin, this.state.txData.to_wallet.currency).format(false) }</Text>
								<Text style={styles.valueNumber2}>{ this.state.txData.currency.iso_code + " " + currencyjs(this.state.txData.amount, this.state.txData.currency).format(false) }</Text>
							</Fragment>
						:
							<Text style={styles.valueNumber}>{ this.state.txData.currency.iso_code + " " + currencyjs(this.state.txData.amount, this.state.txData.currency).format(false) }</Text>
						}

					</View>

					{ this.state.txData.ratio &&
                        <Text style={{...styles.textCenter, marginTop: '10px', marginBottom: '20px'}}>
                          { T.translate("modals.moneyExchange.ratio") }{" "}
                          { currencyjs(this.state.reverseQuote ? 1/this.state.txData.ratio : 1, this.state.txData.history.endsWith('_sent') ? {...this.state.txData.to_wallet.currency, symbol: this.state.txData.to_wallet.currency.iso_code+" " } : {...this.state.txData.from_wallet.currency, symbol: this.state.txData.from_wallet.currency.iso_code+" " }).format(true) }
                          {" = "}
                          { currencyjs(this.state.reverseQuote ? 1 : this.state.txData.ratio, this.state.txData.history.endsWith('_sent') ? {...this.state.txData.from_wallet.currency, symbol: this.state.txData.from_wallet.currency.iso_code+" " } : {...this.state.txData.to_wallet.currency, symbol: this.state.txData.to_wallet.currency.iso_code+" " }).format(true) }
                        </Text>
                    }

					<Text style={styles.h3}>{ T.translate("pdf.tx.sender") }</Text>

					<Text style={styles.textCenter}>
						{ this.state.txData.from_wallet && this.state.txData.from_wallet.owner && this.state.txData.from_wallet.owner.name }
						{ this.state.txData.from_wallet && this.state.txData.from_wallet.owner && this.state.txData.from_wallet.owner.id && " (CAP-U" + this.state.txData.from_wallet.owner.id + ")" }
                        { this.state.txData.from_wallet && this.state.txData.from_wallet.is_crypto && this.state.txData.from_wallet.currency.name }
					</Text>

					{ this.state.txData.from_wallet && !this.state.txData.from_wallet.is_crypto &&
                        <Text style={styles.textCenter}>{ this.state.txData.from_wallet.wallet_address }</Text>
                    }

                    { this.state.txData.history === 'crypto_deposit' && this.state.txData.args.txid &&
                    	<Text style={styles.textCenter}>{ T.translate("pdf.tx.txid") } { this.state.txData.args.txid.replace(/(.{6})/g,"$1 ") }</Text>
                	}

					<Hr />

					<Text style={styles.h3}>{ T.translate("pdf.tx.receiver") }</Text>

					<Text style={styles.textCenter}>
						{ this.state.txData.to_wallet && this.state.txData.to_wallet.owner && this.state.txData.to_wallet.owner.name }
						{ this.state.txData.to_wallet && this.state.txData.to_wallet.owner && this.state.txData.to_wallet.owner.id && " (CAP-U" + this.state.txData.to_wallet.owner.id + ")" }
                        { this.state.txData.to_wallet && this.state.txData.to_wallet.is_crypto && this.state.txData.to_wallet.currency.name }
					</Text>

					{ this.state.txData.to_wallet && this.state.txData.to_wallet.wallet_address &&
                        <Text style={styles.textCenter}>{ this.state.txData.to_wallet.wallet_address }</Text>
                    }

                    { this.state.txData.history === 'crypto_withdraw' && this.state.txData.args.txid &&
                    	<Text style={styles.textCenter}>{ T.translate("pdf.tx.txid") } { this.state.txData.args.txid.replace(/(.{6})/g,"$1 ") }</Text>
                	}

                	{ ['ted_withdraw', 'fiat_withdraw'].includes(this.state.txData.history) && this.state.txData.to_wallet.bank_account.type === 'ted' &&
                		<Fragment>
	                		<Text style={styles.label}>{ T.translate("modals.depositFunds.bankData.holdername") }</Text>
	                		<Text style={styles.item}>{ this.state.txData.to_wallet.bank_account.holder} </Text>
	                		<Text style={styles.label}>{ T.translate("modals.depositFunds.bankData.holderdoc") }</Text>
	                		<Text style={styles.item}>{ this.state.txData.to_wallet.bank_account.doc} </Text>
	                		<Text style={styles.label}>{ T.translate("modals.depositFunds.bankData.bankname") }</Text>
	                		<Text style={styles.item}>{ this.state.txData.to_wallet.bank_account.bank.Code} { this.state.txData.to_wallet.bank_account.bank.Name}</Text>
	                		<Text style={styles.label}>{ T.translate("modals.depositFunds.bankData.agency") }</Text>
	                		<Text style={styles.item}>{ this.state.txData.to_wallet.bank_account.agency}</Text>
	                		<Text style={styles.label}>{ this.state.txData.to_wallet.bank_account.savings ? T.translate("modals.depositFunds.savings") : T.translate("modals.depositFunds.checking") }</Text>
	                		<Text style={styles.item}>{ this.state.txData.to_wallet.bank_account.account}</Text>
	                		<Text style={styles.label}>{ T.translate("modals.depositFunds.nature") }</Text>
	                		<Text style={styles.item}>{ this.state.txData.to_wallet.bank_account.type.toUpperCase()}</Text>

	                		{ this.state.intermediator &&
	                			<Fragment>
									<Hr />

									<Text style={styles.h3}>{ T.translate("pdf.tx.issuer") }</Text>
									<Text style={styles.blackCenter}>{ this.state.intermediator.name }</Text>
									<Text style={styles.blackCenter}>{ T.translate('modals.depositFunds.brazil_Ted.holderDoc') }{ this.state.intermediator.cpfcnpj }</Text>
									<Text style={styles.blackCenter}>{ T.translate('modals.depositFunds.bankData.bankname') }{ this.state.intermediator.bank }</Text>
									<Text style={styles.blackCenter}>{ T.translate('modals.depositFunds.bankData.agency') }{ this.state.intermediator.branch }</Text>
									<Text style={styles.blackCenter}>{ T.translate('modals.depositFunds.bankData.accountno') }{ this.state.intermediator.account }</Text>
								</Fragment>
							}
                		</Fragment>
                	}

                	{ ['sepa_withdraw', 'fiat_withdraw'].includes(this.state.txData.history) && this.state.txData.to_wallet.bank_account.type === 'sepa' &&
                		<Fragment>
	                		<Text style={styles.label}>{ T.translate("modals.depositFunds.bankData.holdername") }</Text>
	                		<Text style={styles.item}>{ this.state.txData.to_wallet.bank_account.holderName} </Text>
	                		<Text style={styles.label}>{ T.translate("modals.depositFunds.bankData.bankname") }</Text>
	                		<Text style={styles.item}>{ this.state.txData.to_wallet.bank_account.bankName}</Text>
	                		<Text style={styles.label}>{ T.translate("modals.depositFunds.bankData.swift") }</Text>
	                		<Text style={styles.item}>{ this.state.txData.to_wallet.bank_account.swift}</Text>
	                		<Text style={styles.label}>{ T.translate("modals.depositFunds.bankData.iban") }</Text>
	                		<Text style={styles.item}>{ this.state.txData.to_wallet.bank_account.iban}</Text>
	                		<Text style={styles.label}>{ T.translate("modals.depositFunds.nature") }</Text>
	                		<Text style={styles.item}>{ this.state.txData.to_wallet.bank_account.type.toUpperCase()}</Text>

	                		{ this.state.intermediator &&
		                		<Fragment>
									<Hr />

									<Text style={styles.h3}>{ T.translate("pdf.tx.issuer") }</Text>
									<Text style={styles.blackCenter}>{ this.state.intermediator.name }</Text>
									<Text style={styles.blackCenter}>{ T.translate('modals.depositFunds.bankData.bankname') }{ this.state.intermediator.bank }</Text>
									<Text style={styles.blackCenter}>{ T.translate('modals.depositFunds.bankData.accountno') }{ this.state.intermediator.account }</Text>
									<Text style={styles.blackCenter}>{ T.translate('modals.depositFunds.bankData.iban') }{ this.state.intermediator.iban }</Text>
									<Text style={styles.blackCenter}>{ T.translate('modals.depositFunds.bankData.swift') }{ this.state.intermediator.bicswift }</Text>
								</Fragment>
							}
                		</Fragment>
                	}

                	{ ['boleto_withdraw', 'fiat_withdraw'].includes(this.state.txData.history) && this.state.txData.to_wallet.bank_account.type === 'boleto' &&
                		<Fragment>
                			{ this.state.txData.to_wallet.bank_account.tipoBoleto === 'BANCO' ?
                            <Fragment>
                                <Text style={styles.label}>
                                    { T.translate("modals.withdrawFunds.bill") }
                                </Text>
                                <Text style={styles.item}>
                                    { this.state.txData.to_wallet.bank_account.bank }
                                </Text>
                                <Text style={styles.label}>
                                	{ T.translate("modals.withdrawFunds.expiration") }
                                </Text>
	                			<Text style={styles.item}>{ moment(this.state.txData.to_wallet.bank_account.expBoleto).format('ll') } </Text>
                            </Fragment>
                            :
                            <Fragment>
                                <Text style={styles.label}>
                                    { T.translate("modals.withdrawFunds.slip") }
                                </Text>
                                <Text style={styles.item}>
                                    { capitalCase(this.state.txData.to_wallet.bank_account.tipoBoleto || "") }
                                </Text>
                            </Fragment>
                            }
	                		<Text style={styles.label}>{ T.translate("modals.withdrawFunds.barCodeShort") }</Text>
	                		<Text style={styles.longText}>{ this.state.txData.to_wallet.bank_account.barcodeBoleto}</Text>
	                		<Text style={styles.label}>{ T.translate("modals.withdrawFunds.digitableLineShort") }</Text>
	                		<Text style={styles.longText}>{ this.state.txData.to_wallet.bank_account.linhaBoleto}</Text>

	                		{ this.state.intermediator && 
	                			<Fragment>
									<Hr />

									<Text style={styles.h3}>{ T.translate("pdf.tx.issuer") }</Text>
									<Text style={styles.blackCenter}>{ this.state.intermediator.name }</Text>
									<Text style={styles.blackCenter}>{ T.translate('modals.depositFunds.brazil_Ted.holderDoc') }{ this.state.intermediator.cpfcnpj }</Text>
									<Text style={styles.blackCenter}>{ T.translate('modals.depositFunds.bankData.bankname') }{ this.state.intermediator.bank }</Text>
									<Text style={styles.blackCenter}>{ T.translate('modals.depositFunds.bankData.agency') }{ this.state.intermediator.branch }</Text>
									<Text style={styles.blackCenter}>{ T.translate('modals.depositFunds.bankData.accountno') }{ this.state.intermediator.account }</Text>
								</Fragment>
							}
                		</Fragment>
                	}

                	{ Boolean(this.state.txData.comments) && 
                		<Fragment>
                			<Hr />
	                		<Text style={styles.h3}>{ T.translate("pdf.tx.ref") }</Text>
	                		<Text style={styles.comments}>{ this.state.txData.comments }</Text>
                		</Fragment>
                	}

                	{ Boolean(this.state.txData.args && this.state.txData.args.invoiceData && this.state.txData.args.invoiceData.description) && 
                		<Fragment>
                			<Hr />
	                		<Text style={styles.h3}>{ T.translate("pdf.tx.ref") }</Text>
	                		{ this.state.txData.args.invoiceData.sender && this.state.txData.args.invoiceData.sender.name && <Text style={styles.h4}>{this.state.txData.args.invoiceData.sender.name}: </Text>}
	                		<Text style={styles.comments}>
	                			{ this.state.txData.args.invoiceData.description }
	                		</Text>
                		</Fragment>
                	}
				</View>
				<Text style={styles.footer}>www.capitual.com</Text>
			</Page>
		</Document>
	)

	render = () => {
		let pdfDocument = this.PDFGenerator()
		return (
			<div id="page-dev">
				<RequireLogin />

				<LoadingAnim isActive={this.state.loading} />
				
				<TopNavBar />
				{ !this.state.loading &&
					<Fragment>
						<PDFDownloadLink document={pdfDocument} fileName="Capitual Receipt.pdf">
							<div className="notification is-link" style={{ margin: 0, borderRadius: 0 }}>
								<Link to={this.props.location.pathname.slice(0, -4)} className='delete' style={{ transform: 'translate(-20px, 13px)' }} />
								{ T.translate("pages.transactions.dlReceipt") }
							</div>
						</PDFDownloadLink>

						<PDFViewer style={styles.viewer}>
							{ pdfDocument }
						</PDFViewer>
					</Fragment>
				}

			</div>
		)
	}
}

export default Receipt