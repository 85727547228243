import React, { Component } from 'react'
import T from 'i18n-react'
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom'

import { Columns, Column,
         Modal, ModalBackground, ModalCard, ModalCardHeader, ModalCardTitle, ModalCardBody, Delete } from 'bloomer'

import alertify from 'alertify.js'

import '../assets/styles/pininput.css'

class PinInput extends Component {
    componentDidMount = () => {
        this.pins.childNodes[0].focus()
    }
    componentDidUpdate = () => {
        this.pins.childNodes[0].focus()
        this.pins.childNodes.forEach( (item, index) => {
            item.value = ''
        })
    }

    onInsertPin = (event, nextPin ) => {
        this.pins.childNodes.forEach( (item, index) => {
            if( index+1 === nextPin)
                item.focus()
        })
    }

    onKeypress = (e, prevPin) => {
        let key = e.keyCode || e.charCode
        if (key == 8 || key == 46) {
            e.stopPropagation()
            e.preventDefault()
            this.pins.childNodes.forEach( (item, index) => {
                item.value = ''
            })
            this.pins.childNodes[0].focus()
        }
    }

    onFinish = () => {
        this.props.onChange(String(this.pins.children[0].value) + String(this.pins.children[1].value) + String(this.pins.children[2].value) + String(this.pins.children[3].value))
    }

    render() {
        return (<Columns className="has-borders" isMultiline isPaddingless>
            <Column className='no-border' style={{ paddingTop: '5rem', paddingBottom: '9rem' }}>
                <Column isSize={12} hasTextAlign="centered" className="no-border">
                    <h2 className="subtitle is-5 has-text-grey is-uppercase is-spaced has-text-weight-light">
                        { T.translate("modals.pin.title") }
                    </h2>
                </Column>
                <Column isSize={12} hasTextAlign="centered" className="no-border">
                    <div className="pins" ref={ ref => this.pins = ref }>
                        <input className="pin" type="password" maxLength={1} onKeyDown={(e) => this.onKeypress(e, 0)} onChange={ (e) => this.onInsertPin(e,2) } autocomplete={"CapSecurity_"+Math.random()} />
                        <input className="pin" type="password" maxLength={1} onKeyDown={(e) => this.onKeypress(e, 1)} onChange={ (e) => this.onInsertPin(e,3) } autocomplete={"CapSecurity_"+Math.random()} />
                        <input className="pin" type="password" maxLength={1} onKeyDown={(e) => this.onKeypress(e, 2)} onChange={ (e) => this.onInsertPin(e,4) } autocomplete={"CapSecurity_"+Math.random()} />
                        <input className="pin" type="password" maxLength={1} onKeyDown={(e) => this.onKeypress(e, 3)} onChange={ (e) => this.onFinish() } autocomplete={"CapSecurity_"+Math.random()}  />
                    </div>
                </Column>
            </Column>
        </Columns>)
    }
}

export function AskPin(title) {
    return new Promise((resolve, reject) => {
        ReactDOM.render(
                <Modal isActive={true} isSize='large'>
                    <ModalBackground className='animated fadeIn' onClick={(ret) => { ReactDOM.render(<React.Fragment />, document.getElementById('safebox')); reject("Cancelled") }} />
                    <ModalCard style={{width: '800px'}}>
                        <ModalCardHeader>
                            <ModalCardTitle>{ title }</ModalCardTitle>
                            <Delete onClick={(ret) => { ReactDOM.render(<React.Fragment />, document.getElementById('safebox')); reject("Cancelled") }} />
                        </ModalCardHeader>
                        <ModalCardBody>
                            <PinInput onChange={(ret) => { ReactDOM.render(<React.Fragment />, document.getElementById('safebox')); resolve(ret)}} />
                            <br/>
                            <br/>
                            <a href="/settings/security#pin">{ T.translate("modals.pin.forgotPin") }</a>
                        </ModalCardBody>
                    </ModalCard>
                </Modal>
            , document.getElementById('safebox'))
    })
}

export function DefPinProc(e) {
    if (e == 'WrongPin') {
        alertify.error(T.translate("modals.pin.wrong"))
    }
    else if (e == 'TooManyAttempts') {
        alertify.okBtn("OK").alert(T.translate("modals.pin.blocked"))
    }
}

export default PinInput
