import React from 'react';
import T from 'i18n-react'
import ReactDOM from 'react-dom';
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import {Dropdown, DropdownTrigger, Button, DropdownMenu, DropdownContent, DropdownItem, Icon } from 'bloomer';

import CurrencyIcon from './CurrencyIcon'

import cap from '../lib/capsdk'

class CryptoSelector extends React.Component {

	state = {
		isDropdownOpen: false,
		currencies: []
	}

	componentWillMount() {
		document.addEventListener('mousedown', this.handleClick, false)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false)
	}

	handleClick = (e) => {
		let thenode = ReactDOM.findDOMNode(this)
		if (thenode.contains(e.target)) return
		this.setState({isDropdownOpen: false})
	}

	componentDidMount = () => {
		cap.requestA('currencies.getCurrencies').then(currencies => {
			this.setState({
				currencies: currencies.filter(item => item.type === 'crypto')
			}, () => {
				this.setDropdownValue(currencies[0])
			})
		})
	}

	openDropdown = () => this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
	
	setDropdownValue = curr => {
		this.setState({isDropdownOpen: false})
		this.props.onChange(curr);
	}

	render() {
		let selectedCurrency = this.props.selected.id ? this.state.currencies.filter(item => item.id === this.props.selected.id) : []
		if (selectedCurrency.length)
			selectedCurrency = selectedCurrency[0]
		else
			selectedCurrency = false

		return (
			<Dropdown isFullWidth isActive={this.state.isDropdownOpen}>
			  <DropdownTrigger  isFullWidth onClick={this.openDropdown}>
			    <Button isOutlined className="is-fullwidth" aria-haspopup="true" aria-controls="dropdown-menu">
			      <span className="icon" style={{ marginRight: 10}}>
			      	{ selectedCurrency && <CurrencyIcon currency={selectedCurrency.iso_code} /> }
			      </span>
			      {selectedCurrency &&
			      	<span style={{marginRight: 10}}>{selectedCurrency.name} </span>
			      }
			      <FontAwesomeIcon icon={["fal", "angle-down"]} />
			    </Button>
			  </DropdownTrigger>
			  <DropdownMenu isFullWidth>
			    <DropdownContent isFullWidth>
			      	{this.state.currencies.length && this.state.currencies.map((row, index) => (
			      		<DropdownItem isFullWidth href="#" onClick={() => this.setDropdownValue(row)} isActive={selectedCurrency && selectedCurrency.id===row.id}>
			      			<span className="icon" style={{ marginRight: 10 }}>
			      				{<CurrencyIcon currency={row.iso_code} />}
			      			</span>
			      			<span style={{ transform: 'translateY(-10px)' }}>{row.name}</span>
			      		</DropdownItem>
                	))}
			    </DropdownContent>
			  </DropdownMenu>
			</Dropdown>
		)
	}
}

export default CryptoSelector;