import React, { Component, Fragment } from 'react';
import T from 'i18n-react'
import Lottie from 'react-lottie';
import currencyjs from 'currency.js'

import {Button, Box, Column, Columns, Hero, HeroHeader, HeroBody, HeroFooter, Modal, ModalBackground, ModalContent, ModalCard, ModalCardBody,ModalCardHeader,ModalCardTitle, ModalClose, Container, Tabs, TabList, Tab, TabLink, Tag, Title, Subtitle } from 'bloomer'

import cap, { Redir } from '../lib/capsdk'

import TopNavBar from "../components/TopNavBar";
import Footer from '../components/Footer';
import PaymentArea from "../components/PaymentArea";
import Avatar from "../components/Avatar";
import LoadingAnim from '../components/LoadingAnim'

import iconError from '../assets/images/SVG/danger_animated.svg'
import iconExpired from '../assets/images/SVG/expired_animated.svg'

import "../assets/styles/page-cappay.css";
import "../assets/styles/mobile.css";

class CapPay extends Component {

	state = {
		paymentMethod: 'capitual'
	}

	componentWillMount = () => {
		this.setState({
			animInstance: Math.random()
		})
	}

	componentDidMount = () => {
		this.updateData()
		this.updateInterval = setInterval(this.updateData, 5000)

		let qs = new URLSearchParams(window.location.search)
		if (qs.has('return_url'))
			this.setState({ returnUrl: qs.get('return_url') })
	}

	componentWillUnmount = () => {
		clearInterval(this.updateInterval)
	}

	updateData = async() => {
		let invoiceID = this.props.match.params.id
		try {
			let invoiceData = await cap.requestA('invoices.getInvoiceDetail', { invoice_id: invoiceID })
			this.setState({
				invoice: invoiceData,
				hasLoaded: true
			})
		} catch (e) {
			this.setState({ error: true, hasLoaded: true })
		}
	}

	renderItemsModal = () => (
		<Modal isActive={this.state.itemsModal}>
			<ModalBackground className='animated fadeIn' onClick={() => this.setState({itemsModal: !this.state.itemsModal }) } />
			<ModalCard className='animated fadeInDown'>
			<ModalContent>
				<Box>
					{ this.state.invoice.description && 
						<blockquote className='blockquote'><strong>{ T.translate("pages.pay.messageFromSender") }</strong> {this.state.invoice.description || T.translate("pages.pay.noMessage")}</blockquote>
					}

					{ Array.isArray(this.state.invoice.products) && this.state.invoice.products.map(item =>
						<Columns>
							<Column isSize='3/4'>{item.quantity} { item.amount }</Column>
							<Column hasTextAlign="right"><b>{ currencyjs(item.price, {...this.state.invoice.currency, formatWithSymbol: true }).format() }</b></Column>
						</Columns>
					)}

					<hr/>
					<Columns>
						<Column isSize="3/4" hasTextAlign="right"><b>Total</b></Column>
						<Column hasTextAlign="right"><b>{ currencyjs(this.state.invoice.amount, {...this.state.invoice.currency, formatWithSymbol: true }).format() }</b></Column>
					</Columns>
				</Box>
			</ModalContent>
			</ModalCard>
			<ModalClose onClick={() => this.setState({itemsModal: !this.state.itemsModal }) } />
		</Modal>
	)

	render() {
		
		return (
			<main className="page-cappay">
				<Redir to={this.state.redir} />
				<LoadingAnim isActive={!this.state.hasLoaded} />
				<Hero isFullHeight>
					<HeroHeader>
						<TopNavBar />
					</HeroHeader>
					<HeroBody>
						<Container>
							<Columns isCentered>
								<Column isSize={5}>
									<Box>
										{ this.state.hasLoaded && this.state.invoice && (this.state.invoice.status === 'paid' || !parseFloat(this.state.invoice.amount)) && 
											<Columns>
												<Column hasTextAlign='centered'>
													<Lottie
			                                            height='150px'
			                                            options={{
			                                                loop: false,
			                                                autoplay: true,
			                                                animationData: require('../assets/images/anim/check.json'),
			                                                rendererSettings: {
			                                                    preserveAspectRatio: 'xMidYMid meet'
			                                                }
			                                            }}
			                                        />
			                                        <br/>
													<Subtitle>{ T.translate("pages.pay.invoicePaid") }</Subtitle>
													<p>
														{ T.translate("pages.pay.furtherContact") }
													</p>
													{ this.state.returnUrl && [<br/>, <Button href={this.state.returnUrl} isFullwidth isColor='primary' isSize='large'>{ T.translate("pages.pay.gotoMerchant") }</Button>] }
												</Column>
											</Columns>
										}
										{ this.state.hasLoaded && this.state.invoice && this.state.invoice.status === 'expired' &&
											<Columns>
												<Column hasTextAlign='centered'>
													<img src={iconExpired+"?"+this.state.animInstance} style={{ width: '100px', height: '100px' }} />
													<Subtitle>{ T.translate("pages.pay.invoiceExpired") }</Subtitle>
													<p>
														{ T.translate("pages.pay.furtherContact") }
													</p>
												</Column>
											</Columns>
										}
										{ this.state.hasLoaded && this.state.invoice && this.state.invoice.status === 'pending' && parseFloat(this.state.invoice.amount) ?
											<Fragment>
												<Columns>
													<Column hasTextAlign="centered">
														<Avatar style={{borderRadius: '150px'}} isSize="128x128" url={this.state.hasLoaded && this.state.invoice.sender.avatar} />
														<br/>
														<b className="subtitle">{this.state.hasLoaded && this.state.invoice.sender.name}</b>
														<br/>
														<br/>
														<Tag isColor='light' isSize="large" style={{cursor: 'pointer'}} onClick={() => this.setState({itemsModal: !this.state.itemsModal }) }>
															{ this.state.hasLoaded && currencyjs(this.state.invoice.amount, {...this.state.invoice.currency, formatWithSymbol: true }).format() }
														</Tag>
													</Column>
												</Columns>
												<br/>

												<Tabs isSize="small" isAlign="centered" isBoxed="true" isToggle="true">
													<TabList>
														<Tab isActive={this.state.paymentMethod === 'capitual'}>
															<TabLink onClick={() => this.setState({paymentMethod: 'capitual'})}>
																<span>{ T.translate("pages.pay.withCapitual") }</span>
															</TabLink>
														</Tab>
														<Tab isActive={this.state.paymentMethod === 'crypto'}>
															<TabLink onClick={() => this.setState({paymentMethod: 'crypto'}) }>
																<span>{ T.translate("pages.pay.withCrypto") }</span>
															</TabLink>
														</Tab>
													</TabList>
												</Tabs>
												
												<Columns isCentered>	
													{ this.state.invoice &&
														<PaymentArea method={this.state.paymentMethod} invoice={this.state.invoice} onPay={this.componentDidMount} />
													}
												</Columns>
											</Fragment>
										: ""}
										{ this.state.error || (this.state.hasLoaded && this.state.invoice && this.state.invoice.status === 'cancelled') &&
											<Columns>
												<Column hasTextAlign='centered'>
													<img src={iconError+"?"+this.state.animInstance} style={{ width: '100px', height: '100px' }} />
													<Subtitle>{ T.translate("pages.pay.invalidInvoice") }</Subtitle>
													<p>
														{ T.translate("pages.pay.furtherContact") }
													</p>
												</Column>
											</Columns>
										}
									</Box>
								</Column>
							</Columns>
						</Container>
						
						
					</HeroBody>
					<HeroFooter>
						<Footer />
					</HeroFooter>
				
				</Hero>

								
						


				{ this.state.itemsModal && this.renderItemsModal() }

				
			</main>
		)
	}
}

export default CapPay;