export default function(text) {
	var node = document.createElement('textarea');
	var selection = document.getSelection();

	node.textContent = text;
	document.body.appendChild(node);

	selection.removeAllRanges();
	node.select();
	document.execCommand('copy');

	selection.removeAllRanges();
	document.body.removeChild(node);

	return true;
}