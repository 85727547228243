import React from 'react';
import T from 'i18n-react'
import { Help } from 'bloomer';
import {Tooltip} from 'react-lightweight-tooltip';
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import "../assets/styles/float-label-input.css";


const FloatLabelInput = (props) => {
	let sanitizedProps = {...props}
	delete sanitizedProps.children
    return (
        <main className={`control float-label-input ${props.isLoading && 'is-loading'}`}>
            <input {...sanitizedProps} placeholder={props.placeholder} className={`${props.readOnly && 'readonly'} ${props.isColor && 'is-'+props.isColor} ${props.className}`} />

            <label>
                {props.label}
                { props.tooltip &&
                    <Tooltip content={props.tooltip}>
                        &nbsp;
                        <FontAwesomeIcon icon={["fal", "info-circle"]} />
                    </Tooltip>
                }
            </label>

            { props.children }

            { props.helpText && (<Help isColor={props.isColor}>{props.helpText}</Help>) }
        </main>
    );
}

export default FloatLabelInput;
