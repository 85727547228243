/* eslint-disable import/first */

import React, { Component } from 'react'
import T from 'i18n-react'
import { __ } from '../helpers/i18n'
import FloatLabelInput from './FloatLabelInput'
import Keyboard from 'react-simple-keyboard'
import "simple-keyboard/build/css/index.css";
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
let zxcvbn
import 'bulma-popover/css/bulma-popover.css'

class FloatLabelPassword extends Component {
    state = {
        val: "",
        kblayout: "default",
        kbActive: false,
        caps: false,
        strengthLevel: null,
        strengthTip: ''
    }

    phrases = {}

    constructor({...props}) {
        super(props)
        this.state = {
            ...this.state,
            val: props.value || "",
            kblayout: props.layout || "default"
        }
    }

    componentWillMount = async() => {
        if (this.props.strength) {
            zxcvbn = await import('@contentpass/zxcvbn')
            this.setState({ hasLoaded: true })
        }
    }

    componentDidUpdate = () => {
        this.phrases = {
            use_a_few_words: T.translate('components.passwordInput.feedback.use_a_few_words'),
            no_need_for_mixed_chars: T.translate('components.passwordInput.feedback.no_need_for_mixed_chars'),
            uncommon_words_are_better: T.translate('components.passwordInput.feedback.uncommon_words_are_better'),
            straight_rows_of_keys_are_easy: T.translate('components.passwordInput.feedback.straight_rows_of_keys_are_easy'),
            short_keyboard_patterns_are_easy: T.translate('components.passwordInput.feedback.short_keyboard_patterns_are_easy'),
            use_longer_keyboard_patterns: T.translate('components.passwordInput.feedback.use_longer_keyboard_patterns'),
            repeated_chars_are_easy: T.translate('components.passwordInput.feedback.repeated_chars_are_easy'),
            repeated_patterns_are_easy: T.translate('components.passwordInput.feedback.repeated_patterns_are_easy'),
            avoid_repeated_chars: T.translate('components.passwordInput.feedback.avoid_repeated_chars'),
            sequences_are_easy: T.translate('components.passwordInput.feedback.sequences_are_easy'),
            avoid_sequences: T.translate('components.passwordInput.feedback.avoid_sequences'),
            recent_years_are_easy: T.translate('components.passwordInput.feedback.recent_years_are_easy'),
            avoid_recent_years: T.translate('components.passwordInput.feedback.avoid_recent_years'),
            avoid_associated_years: T.translate('components.passwordInput.feedback.avoid_associated_years'),
            dates_are_easy: T.translate('components.passwordInput.feedback.dates_are_easy'),
            avoid_associated_dates_and_years: T.translate('components.passwordInput.feedback.avoid_associated_dates_and_years'),
            top10_common_password: T.translate('components.passwordInput.feedback.top10_common_password'),
            top100_common_password: T.translate('components.passwordInput.feedback.top100_common_password'),
            very_common_password: T.translate('components.passwordInput.feedback.very_common_password'),
            similar_to_common_password: T.translate('components.passwordInput.feedback.similar_to_common_password'),
            a_word_is_easy: T.translate('components.passwordInput.feedback.a_word_is_easy'),
            names_are_easy: T.translate('components.passwordInput.feedback.names_are_easy'),
            common_names_are_easy: T.translate('components.passwordInput.feedback.common_names_are_easy'),
            capitalization_doesnt_help: T.translate('components.passwordInput.feedback.capitalization_doesnt_help'),
            all_uppercase_doesnt_help: T.translate('components.passwordInput.feedback.all_uppercase_doesnt_help'),
            reverse_doesnt_help: T.translate('components.passwordInput.feedback.reverse_doesnt_help'),
            substitution_doesnt_help: T.translate('components.passwordInput.feedback.substitution_doesnt_help'),
            user_dictionary: T.translate('components.passwordInput.feedback.user_dictionary')
        }
    }

    handleKeypress = (input) => {
        if ((input == '{shift}' || input == '{lock}') && this.state.kblayout==(this.props.kblayout || "default"))
            this.setState({kblayout: 'shift'})
        else if ((input == '{shift}' || input == '{lock}') && this.state.kblayout == 'shift')
            this.setState({kblayout: (this.props.kblayout || "default")})
    }

    handleChange = (val) => {
        this.setState({val})
        this.props.onChange({target: {
            type: 'password',
            name: this.props.name,
            value: val
        }})
    }

    handleInputChange = (e) => {
        this.setState({
            val: e.target.value
        })
        if (this.keyboard) this.keyboard.setInput(e.target.value);

        if (this.props.strength && e.target.value && this.state.hasLoaded) {
            let check = zxcvbn(e.target.value, { feedback_messages: this.phrases, user_inputs: ['capitual', 'bitcoin', 'crypto'] }),
                strengthColor,
                strengthTip,
                strengthBg = '',
                passwordLevel
            switch (check.score) {
                case 0:
                    strengthColor = '#ffcdd2'
                    strengthBg = '#ffebee'
                    passwordLevel = T.translate("components.passwordInput.strength1")
                    break
                case 1:
                    strengthColor = '#ffe0b2'
                    strengthBg = '#fff3e0'
                    passwordLevel = T.translate("components.passwordInput.strength2")
                    break
                case 2:
                    strengthColor = '#fff9c4'
                    strengthBg = '#fffde7'
                    passwordLevel = T.translate("components.passwordInput.strength3")
                    break
                case 3:
                    strengthColor = '#f0f4c3'
                    strengthBg = '#f9fbe7'
                    passwordLevel = T.translate("components.passwordInput.strength4")
                    break
                case 4:
                    strengthColor = '#c8e6c9'
                    strengthBg = '#e8f5e9'
                    passwordLevel = T.translate("components.passwordInput.strength5")
                    break
            }

            let list = check.feedback.suggestions.length ? ('<ul><li>'+check.feedback.suggestions.join("</li><li>")+'</li></ul>') : ''

            strengthTip = (check.feedback.warning ? check.feedback.warning + '. ' : '') + list

            this.setState({
                strength: check.score,
                strengthColor,
                strengthTip,
                passwordLevel,
                strengthBg
            })
        } else
            this.setState({strength: null, strengthColor: null, strengthTip: '', passwordLevel: this.state.hasLoaded ? T.translate("components.passwordInput.startTyping") : ''})

        this.props.onChange(e)
    }

    kblayout = {
      'default': [
        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        '{tab} q w e r t y u i o p [ ] \\',
        '{lock} a s d f g h j k l ; \'',
        '{shift} z x c v b n m , . / {shift}',
        '.com @ {space}'
      ],
      'shift': [
        '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
        '{tab} Q W E R T Y U I O P { } |',
        '{lock} A S D F G H J K L : "',
        '{shift} Z X C V B N M < > ? {shift}',
        '.com @ {space}'
    ]
    }

    renderElement = () => {
        return (
            <div class="field has-addons" onKeyPress={this.checkCaps} style={this.state.strengthColor && {backgroundColor: this.state.strengthBg, borderBottom: '1px solid '+this.state.strengthColor}}>
              <div class="control is-fullwidth">
                <FloatLabelInput style={{ paddingRight: '40px' }} type="password" {...this.props} className={(this.props.strength ? 'popover-trigger' : '')} onFocus={() => this.setState({ focused: true })} onBlur={() => this.setState({ focused: false })} onChange={this.handleInputChange} value={!this.state.kbActive ? this.props.value : this.state.val} isLoading={!this.state.hasLoaded && this.props.strength}>
                    <a class="button keybd-button" onClick={ () => this.setState({ kbActive: !this.state.kbActive, val: this.props.value })}>
                      <FontAwesomeIcon icon={["fal", "keyboard"]} />
                    </a>
                </FloatLabelInput>
              </div>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                { this.props.strength ?
                    <div class={"popover is-popover-top "+(this.state.focused && 'is-popover-active')} style={{width: '100%'}}>
                      { this.renderElement() }
                      <div class="popover-content">
                          { this.state.passwordLevel ?
                            <React.Fragment>
                                <b>{ this.state.passwordLevel }</b>
                                <br/>
                                <p dangerouslySetInnerHTML={{ __html: this.state.strengthTip }} />
                            </React.Fragment>
                            :
                            <p>{ T.translate("components.passwordInput.startTyping") }</p>
                            }
                      </div>
                  </div>
                  :
                  this.renderElement()
                }



                { this.state.kbActive &&
                <Keyboard
                  layout={this.kblayout}
                  ref={r => this.keyboard = r}
                  layoutName={this.state.kblayout}
                  onKeyPress={this.handleKeypress}
                  onChange={ this.handleChange }
                />
                }
            </React.Fragment>
        )
    }
}

export default FloatLabelPassword
