import React from 'react';
import T from 'i18n-react'
import {Image} from 'bloomer';

import  "../assets/styles/avatar.css";

import defaultAvatar from "../assets/images/SVG/avatar-default.svg";
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import DropToUpload from 'react-drop-to-upload'

import thumbor from '../helpers/thumbor'


const styles = {
    display: 'inline-flex',
    // width: '100%'
}

const Avatar = ({url, isSize, hasChangeHover, onAlterClick, onChange, ...props}) => {
    let lastTarget
    let setDragStyle = ({target}) => {
        if (!hasChangeHover) return
        lastTarget = target
        target.style.border = '4px dashed #999'
        target.style.borderRadius = '5px'
        target.style.background = '#eee'
    }
    let unsetDragStyle = ({target}) => {
        if (!hasChangeHover) return
        lastTarget = target
        target.style.border = 'none'
        target.style.background = 'unset'
        target.style.borderRadius = '0px'
    }
    let handleChange = ({target}) => {
        if (!hasChangeHover) return
        onChange(target.files)
    }
    let handleDrop = (files) => {
        if (!hasChangeHover) return
        unsetDragStyle({target: lastTarget})
        onChange(files)
    }
    return typeof onChange === 'undefined' ? (
        <div className="avatar" style={props.styles || styles}>
            { url ? (
                <Image src={thumbor(url, isSize)} alt={ T.translate("components.avatar.alt") } className="is-rounded" isSize={isSize} {...props}  />
            ) : (
                <Image src={defaultAvatar} alt={ T.translate("components.avatar.alt") } className="is-rounded" isSize={isSize} {...props}  />
            ) }
        </div>
    ) : (
        <label style={props.styles || styles}>
            <input onChange={ handleChange } class="file-input is-hidden" type="file" name="resume" />
            <DropToUpload style={props.styles || styles} onDrop={ handleDrop } onOver={ setDragStyle } onLeave={ unsetDragStyle }>
                <div className="avatar" style={props.styles || styles}>
                    { hasChangeHover && <div className="overlay-change-avatar" onClick={onAlterClick}> <FontAwesomeIcon icon={['fal', 'camera']}/> </div> }

                    { url ? (
                        <Image src={thumbor(url, isSize)} alt={ T.translate("components.avatar.alt") } className="is-rounded" isSize={isSize} {...props}  />
                    ) : (
                        <Image src={defaultAvatar} alt={ T.translate("components.avatar.alt") } className="is-rounded" isSize={isSize} {...props}  />
                    ) }
                </div>
            </DropToUpload>
        </label>
    );
}


export default Avatar;
