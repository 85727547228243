import React from 'react';
import { Hero, HeroBody, Container, Columns, Column, Title } from "bloomer";
import FontAwesomeIcon from "@fortawesome/react-fontawesome"

import bg from '../assets/images/bg-header-overview.png'

const styles = {
    background: `url( ${bg} ) center no-repeat`
}

const HeaderOverview = ({ title, icon, isColor, useCustomIcon, ...rest }) => {
    return (
        <Hero style={styles} isColor={isColor}>
            <HeroBody>
                <Container>
                    <Columns className="blocks" isVCentered>
                        <Column>
                            <div className="has-vcentered">
                                {useCustomIcon && <i className={`icon i-${useCustomIcon} is-32 `} style={{marginRight: '10px'}} />}
                                <Title style={{fontWeight: 300, padding: '30px 0'}}>
                                    {icon && <FontAwesomeIcon style={{marginRight: '10px'}} icon={['fal', icon]} />}
                                    
                                    
                                    {title}
                                    { rest.children }
                                </Title>
                            </div>
                            
                            <br/>
                        </Column>
                    </Columns>
                </Container>
            </HeroBody>
        </Hero>
        
    );
}

export default HeaderOverview;  