import React, { Component } from 'react'
import 'bulma-extensions/bulma-pageloader/bulma-pageloader.min.css'
import '../assets/styles/caploading.css'
import loadingAnim from '../assets/images/anim/loading.gif'

class LoadingAnim extends Component {
    render() {
        return this.props.isActive ? (
            <div class="pageloader is-active is-white caploading"><span class="title" style={{ background: this.props.progress ? 'linear-gradient(90deg, hsl(0, 0%, 96%) '+this.props.progress+'%, white '+this.props.progress+'%)' : null }}><img src={loadingAnim} /></span></div>
        ) : ""
    }
}

export default LoadingAnim
