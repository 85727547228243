import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import T from 'i18n-react'

import FontAwesomeIcon from "@fortawesome/react-fontawesome";

import { NavLink as Link } from "react-router-dom";

import globalStorage from 'react-global-storage'

import i18n, { __ } from '../services/i18n'
import cap from '../lib/capsdk.js'
import { setLanguage, detectLanguage } from '../helpers/i18n'

import {Tag, Dropdown, DropdownItem, DropdownTrigger, DropdownMenu, DropdownContent, DropdownDivider, Button, Icon} from 'bloomer'

class AppFooter extends Component {
	state = {
		i18nswitcher: false
	}

	componentWillMount() {
		document.addEventListener('mousedown', this.handleClick, false)
		this.i18nselector = React.createRef()
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false)
	}

	setLang = (lang) => {
		// set sms and email
		cap.requestA('members.profileset', {args: { locale: lang }})

		this.setState({i18nswitcher: false});
		setLanguage(lang)

		// reload
		globalStorage.state.reload()
	}

	handleClick = (e) => {
		let thenode = ReactDOM.findDOMNode(this);
		if (thenode.contains(e.target)) return;
		this.setState({i18nswitcher: false});
	}

	render = () => {
	    return (
			<div id="page-footer" className='is-hidden-mobile'>
				<footer class="footer">
					<div class="container is-fluid">
						<div className="columns is-vcentered">

							<div className="column no-border is-3">
								<p>
									<span className='tooltip' data-tooltip={require('../../package.json').version}>
										&copy; {new Date().getFullYear()}
									</span>
								</p>
							</div>
							<div className="column is-6 no-border has-text-centered">
								<nav class="breadcrumb has-bullet-separator" aria-label="breadcrumbs">
									<ul className="is-marginless" style={{ justifyContent: 'center' }}>
										<li><a href="https://cappay.capitual.com">{ T.translate('global.footer.merchants') }</a></li>
										<li><Link to="/apps/mine">{ T.translate('global.footer.developers') }</Link></li>
										<li><a href="https://help.capitual.com">{ T.translate('global.footer.support') }</a></li>
									</ul>
								</nav>
							</div>
							<div className="column is-2 no-border has-text-right" ref="i18nselector">
								<p id="i18nsel">
									<Dropdown className="is-up" isAlign="right" isActive={this.state.i18nswitcher}>
									  <DropdownTrigger onClick={() => this.setState({i18nswitcher: !this.state.i18nswitcher})}>
									    <Button className="is-white is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
											<span class="icon">
											</span>
											<span className="is-spaced has-text-lighter">{ T.translate("iso_639_1").toUpperCase() }</span>
											<span class="icon">
												<FontAwesomeIcon icon={["fal", "angle-up"]} isSize="small" />
											</span>

									    </Button>
									  </DropdownTrigger>
									  <DropdownMenu>
									    <DropdownContent className="has-text-left i18noptions">
									    	{ i18n.getLanguages().map(lang =>
									    		  <DropdownItem href="javascript:void(0);" onClick={() => this.setLang(lang.iso_639_1 + '-' + lang.iso_3166_1)} isActive={ (lang.iso_639_1 + '-' + lang.iso_3166_1 === detectLanguage()) }>
											      	<strong title={ (lang.iso_639_1 + '-' + lang.iso_3166_1 !== detectLanguage()) ? T.translate('languages.'+lang.iso_639_1 + '-' + lang.iso_3166_1) : '' }>{ lang.language }</strong>
											      </DropdownItem>
									    	)}
									    </DropdownContent>
									  </DropdownMenu>
									</Dropdown>
								</p>
							</div>
							<div className='column is-1' />
						</div>

					</div>
				</footer>
			</div>

	    );
	}
}

export default AppFooter;
