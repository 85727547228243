import React from 'react'
import T from 'i18n-react'
import { default as strings } from '../i18n/strings.json'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import momentLocalizer2 from 'react-widgets-moment-localizer'

import "moment/locale/pt-br"

const configure = require('react-widgets/lib/configure')

const fallbackLanguage = 'en-US'

function detectLanguage() {
	if (localStorage.lang)
		return localStorage.lang
	else {
		let available_languages = Object.keys(strings)
		let browserlang = navigator.language || navigator.userLanguage
		if (available_languages.includes(browserlang))
			return browserlang
		else if (available_languages.includes(browserlang.substr(0, 2)))
			return browserlang.substr(0, 2)
		else
			return fallbackLanguage
	}
}

function detectCountry() {
	let lang = detectLanguage().split("-")
	if (lang.length === 2)
		return lang[1].substr(0, 2).toLowerCase()
	else
		return lang[0].substr(0, 2).toLowerCase()

}

function setLanguage(to) {
	let available_languages = Object.keys(strings)
	let opts = {
		MDFlavor: 1,
		notFound: key => {
			console.log("i18n key not found: ", key, available_languages)
			return key
		}
	}

	if (available_languages.includes(to)) {
		T.setTexts(strings[to], opts)
		localStorage.setItem('lang', to)
		Moment.locale(to)
	}
	else if (available_languages.includes(to.substr(0, 2))) {
		T.setTexts(strings[to.substr(0, 2)], opts)
		localStorage.setItem('lang', to.substr(0, 2))	
		Moment.locale(to.substr(0, 2))
	} else {
		T.setTexts(strings[fallbackLanguage], opts)
		Moment.locale(fallbackLanguage)
	}
	momentLocalizer()
	configure.setDateLocalizer(momentLocalizer2(Moment))
}

function getAvailableLangs() {
	return Object.keys(strings)
}

export { fallbackLanguage,
	detectLanguage,
	detectCountry,
	setLanguage,
	getAvailableLangs }