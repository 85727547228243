import React from "react";
import T from 'i18n-react'
import {
  Hero,
  HeroBody,
  HeroFooter,
  Container,
  Box,
  Columns,
  Column,
  Image,
  Button,
  Title,
  Subtitle,
  Card,
  CardHeader,
  CardHeaderTitle,
  CardContent,
  CardFooter,
  CardFooterItem,
  Modal,
  ModalBackground,
  ModalClose,
  ModalContent,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHeader,
  ModalCardTitle
} from "bloomer";
import alertify from 'alertify.js'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import AvatarCropper from "@jesobreira/react-avatar-edit";

import TopNavBar from '../components/TopNavBar'
import Footer from '../components/Footer'
import { AskPin, DefPinProc } from "../components/PinInput"
import Avatar from "../components/Avatar"

import '../assets/styles/page-api.css'

import devNoApps from '../assets/images/SVG/dev-no-apps.svg'

import cap, { RequireLogin } from "../lib/capsdk"

class Developers extends React.Component {
	state = {
		myApps: [],
		selectedApp: null,
		selectedOption: null,
		revealedPrivKeys: {},
		appDomains: [],
		domainConfirming: {}
	}

	componentDidMount = () => {
		this.updateMyApps()
	}

	updateMyApps = async() => {
		cap.requestA('apps.listApps').then(myApps => this.setState({ myApps }))
	}

	selectApp = item => {
		this.setState({
			selectedApp: item.id,
			selectedOption: 'appInfo',
			authorName: item.author,
			authorEmail: item.author_email,
			authorPhone: item.author_phone,
			tosUrl: item.tos,
			ppUrl: item.pp
		})
	}

	createNewApp = async() => {
		let appName = await alertify.okBtn(T.translate("global.labels.ok")).prompt("App Name:")
		if (appName && appName.inputValue) {
			cap.requestA('apps.newApp', { name: appName.inputValue }).then(appId => {
				this.updateMyApps()
				this.setState({
					selectedApp: appId,
					selecteddOption: 'appInfo'
				}).catch(e => {
					if (e === 'AppNameExists') {
						alertify.alert("There is already an app using this name. Try another one.")
					}
				})
			}).catch(e => {
				if (e === 'AppNameExists') {
					alertify.alert("There is already an app using this name. Try another one.")
				}
			})
		}
	}

	revealPrivKey = async() => {
		if (Object.keys(this.state.revealedPrivKeys).includes(String(this.state.selectedApp))) {
			let newPKState = this.state.revealedPrivKeys
			delete newPKState[this.state.selectedApp]
			this.setState({ revealedPrivKeys: newPKState })
		} else {
			try {
				let pin = await AskPin("Reveal App Private Key")
				let privKey = await cap.requestA('apps.getPrivateKey', { appid: this.state.selectedApp, pin })
				let newPKState = this.state.revealedPrivKeys
				newPKState[this.state.selectedApp] = privKey
				this.setState({ revealedPrivKeys: newPKState })
			} catch (e) {
				DefPinProc(e)
			}
		}
	}

	revokePrivKey = async() => {
		let check = await alertify.okBtn("Yes, REVOKE").cancelBtn("No, go back").confirm("Are you sure you want to revoke the private key for this app?")
		if (check.buttonClicked == "ok") {
			try {
				let pin = await AskPin("Revoke App Private Key")
				cap.requestA('apps.revokePrivateKey', { appid: this.state.selectedApp, pin }).then(res => {
					if (res) {
						this.setState({
							revealedPrivKeys: {}
						})
						alertify.success("Private key recreated!")
					}
				}).catch(e => DefPinProc(e))
			} catch (e) {
				DefPinProc(e)
			}
		}
	}

	removeApp = async() => {
		let check = await alertify.okBtn("Yes, DELETE APP").cancelBtn("No, keep app").confirm("Are you sure you want to permanently delete this app?")
		if (check.buttonClicked == "ok") {
			try {
				let pin = await AskPin("Delete App")
				cap.requestA('apps.removeApp', { appid: this.state.selectedApp, pin }).then(res => {
					if (res === 'ok') {
						this.setState({
							selectedApp: null,
							selectedOption: null,
							revealedPrivKeys: {},
							appDomains: []
						}, this.updateMyApps)
					}
				}).catch(e => DefPinProc(e))
			} catch (e) {
				DefPinProc(e)
			}
		}
	}

	getAppDomains = () => {
		cap.requestA('apps.listDomains', { appid: this.state.selectedApp }).then(appDomains => this.setState({ appDomains }))
	}

	addDomain = async() => {
		let domain = await alertify.okBtn(T.translate("global.labels.ok")).prompt("Domain name (e.g. capitual.com):")
		if (domain && domain.inputValue) {
			try {
				let pin = await AskPin("Add Domain to App")
				await cap.requestA('apps.addDomain', { appid: this.state.selectedApp, pin, domain: domain.inputValue })
				this.getAppDomains()
			} catch (e) {
				if (e === 'DomainExists')
					alertify.alert("The domain "+domain+" seems to be already associated to another application.")
				else if (e === 'InvalidDomain')
					alertify.alert("The domain "+domain+" seems to be invalid.")
				else
					DefPinProc(e)
			}
		}
	}

	confirmDomain = domainData => {
		this.setState({
			domainConfirming: domainData
		})
	}

	removeDomain = async(domain) => {
		let check = await alertify.okBtn("Yes, REMOVE").cancelBtn("No, keep it").confirm("Are you sure you want to delete this domain from this app?")
		if (check.buttonClicked == "ok") {
			try {
				let pin = await AskPin("Delete Domain from App")
				cap.requestA('apps.removeDomain', { appid: this.state.selectedApp, pin, domain }).then(res => {
					if (res === 'ok') {
						this.getAppDomains()
					}
				}).catch(e => DefPinProc(e))
			} catch (e) {
				DefPinProc(e)
			}
		}
	}

	requestConfirmDomain = domain => {
		cap.requestA('apps.confirmDomain', {
			appid: this.state.selectedApp,
			domain
		}).then(res => {
			if (res === 'ok') {
				alertify.alert("Domain confirmation requested and will be processed soon.")
			}
		})
	}

	changeAvatar = files => {
		if (files.length) {
		  let file = files[0];
		  if (!file.name.match(/\.(jpg|jpeg|gif|bmp|png)$/i)) {
		    alertify.okBtn("OK").alert(T.translate("pages.settings.profile.avatarWrongExtension"));
		    return;
		  }
		  let reader = new FileReader();
		  reader.readAsDataURL(file);
		  reader.onload = () => {
		    this.setState({ cropAvatar: reader.result });
		  };
		  reader.onerror = error => {
		    console.log("Error: ", error);
		  };
		}
	};

	handleCrop = b64 => {
		this.cropb64 = b64;
	};

	handleCropClose = async (status) => {
		if (!status) {
		  // cancelled
		  return this.setState({
		    cropAvatar: false
		  })
		}
		// update
		this.setState({
		  avatar: this.cropb64,
		  cropAvatar: false
		});

		// actually upload
		let url = await cap.requestA("apps.editApp", {
		  sess_key: cap.sess_key,
		  appid: this.state.selectedApp,
		  logoB64: this.cropb64
		});
		delete this.cropb64;
		this.updateMyApps()
	};

	saveInfo = info => {
		let changes = {
			appid: this.state.selectedApp
		}

		if (info === 'author') {
			changes.author = this.state.authorName
			changes.author_email = this.state.authorEmail
			changes.author_phone = this.state.authorPhone
		}

		else if (info === 'legal') {
			changes.tos = this.state.tosUrl
			changes.pp = this.state.ppUrl
		}

		else if (info === 'name')
			changes.name = this.state.newAppName

		cap.requestA('apps.editApp', changes).then(res => {
			if (res === 'ok') {
				alertify.success("Information saved!")
				this.updateMyApps()
			}
		}).catch(err => {
			if (err === 'InvalidAuthorEmail')
				alertify.alert("The author email looks invalid.")
			else if (err === 'InvalidAuthorPhone')
				alertify.alert("The author phone looks invalid.")
			else if (err === 'AuthorExists')
				alertify.alert("It looks like another user is already using such author information. If you believe this is an error, please get in touch to our support.")
			else if (err === 'InvalidTos')
				alertify.alert("The link for your Terms of Use looks invalid.")
			else if (err === 'InvalidPp')
				alertify.alert("The link for your Privacy Policy looks invalid.")
			else if (err === 'AppNameExists')
				alertify.alert("There is already an app using this app name.")
		})
	}

	changeAppName = async() => {
		let appName = await alertify.okBtn(T.translate("global.labels.ok")).prompt("App Name:")
		if (appName && appName.inputValue) {
			this.setState({
				newAppName: appName.inputValue
			}, () => this.saveInfo("name"))
		}
	}

	render() {

		let selectedAppInfo = this.state.myApps.filter(item => item.id === this.state.selectedApp)
		if (selectedAppInfo.length)
			selectedAppInfo = selectedAppInfo[0]
		else
			selectedAppInfo = null

		return (
			<div id="page-dev">
				<RequireLogin />
				
				<TopNavBar />

				<Modal isActive={this.state.domainConfirming.app}>
			        <ModalBackground onClick={() => this.setState({ domainConfirming: {} })} />
			        <ModalCard className='animated fadeInDown'>
			          <ModalCardHeader><ModalCardTitle>Confirm Domain</ModalCardTitle></ModalCardHeader>
			          <ModalCardBody>
			          	<p>Please <strong>choose one of the following methods</strong> to confirm your domain.</p>
			          	<br/>
			          	<h2 class='subtitle'>Option 1: By DNS</h2>
			          	<p>Create a new DNS entry on your domain with the following data:</p>
			          	<table class="table">
			          		<tr>
			          			<th>Type</th>
			          			<td>TXT</td>
			          		</tr>
			          		<tr>
			          			<th>Name</th>
			          			<td><code>_capapp{ this.state.domainConfirming.app }.{ this.state.domainConfirming.domain }</code></td>
			          		</tr>
			          		<tr>
			          			<th>Value</th>
			          			<td><code>{ this.state.domainConfirming.secret_key }</code></td>
			          		</tr>
			          		<tr>
			          			<th>TTL</th>
			          			<td>0</td>
			          		</tr>
			          	</table>

			          	<h2 class='subtitle'>Option 2: By HTTP</h2>
			          	<p>In your server's document root, create a file named <code>_cfapp{ this.state.domainConfirming.app }.html</code> with the following content:</p>
			          	<pre>{ this.state.domainConfirming.secret_key }</pre>
			          	<p>Your file should be accessible through <a href={"http://" + this.state.domainConfirming.domain + "/_cfapp" + this.state.domainConfirming.app + ".html" } rel="nofollow" target="_blank">this test link</a>.</p>
			          </ModalCardBody>
			          <ModalCardFooter>
			            <Button
			              isColor="primary"
			              onClick={() =>
			                this.setState({ domainConfirming: {} }) ||
			                this.requestConfirmDomain(this.state.domainConfirming.domain)
			              }
			            >
			              Check Now
			            </Button>
			            <Button
			              isColor="primary"
			              isInverted
			              onClick={() =>
			                this.setState({ domainConfirming: {} })
			              }
			            >
			              Close
			            </Button>
			          </ModalCardFooter>
			        </ModalCard>
			        <ModalClose />
			      </Modal>

				<Columns className='main-dev-panel'>
					<Column isSize={2} className='column-1'>
						<div className='section'>

							<Button isFullWidth isColor='primary' onClick={this.createNewApp}>
								Create new app
							</Button>

							<br/>

							<aside className='menu'>
								<p class="menu-label">
									My Apps
								</p>
								{ Boolean(this.state.myApps.length) &&
								<ul className='menu-list'>
									{ this.state.myApps.map(item => (
										<li>
											<a
												className={item.id === this.state.selectedApp && "is-active"}
												href="#"
												onClick={() => this.selectApp(item)}
											>
												{ item.name }
											</a>
										</li>
									))}
								</ul>
								}
								
							</aside>
						</div>
					</Column>

					{ this.state.selectedApp &&
					<Column isSize={2} className='column-2'>
						<div className='section'>
							<aside className='menu'>
								<ul className='menu-list'>
									<li>
										<a className={this.state.selectedOption === 'appInfo' && "is-active"} onClick={() => this.setState({ selectedOption: 'appInfo' }) }>Basic Info</a>
										<a className={this.state.selectedOption === 'domains' && "is-active"} onClick={() => [this.getAppDomains(), this.setState({ selectedOption: 'domains' })] }>Domains</a>
										<a className={this.state.selectedOption === 'maintenance' && "is-active"} onClick={() => this.setState({ selectedOption: 'maintenance' }) }>Maintenance</a>
									</li>
								</ul>
							</aside>
						</div>
					</Column>
					}

					{ this.state.selectedOption &&
					<Column isSize={8} className='column-3'>
						{ selectedAppInfo &&
							<React.Fragment>

								{ this.state.selectedOption === 'appInfo' && 
								<div className='section'>
									<Card>
										<CardHeader>
											<CardHeaderTitle>App Information</CardHeaderTitle>
										</CardHeader>
										<CardContent>
											<Columns>
												<Column isSize={4}>
													{this.state.cropAvatar ? (
									                  <div class="cropavatar">
									                    <div class="overlay" />
									                    <AvatarCropper
									                      onClose={this.handleCropClose}
									                      onCrop={this.handleCrop}
									                      src={this.state.cropAvatar}
									                      height={250}
									                      width={250}
									                      isSize='250'
									                    />
									                  </div>
									                ) : (
									                  <Avatar
									                    url={selectedAppInfo.logo}
									                    onChange={this.changeAvatar}
									                    style={{ width: "250px", height: "250px" }}
									                    className="is-fullwidth"
									                    isSize='250'
									                    hasChangeHover
									                  />
									                )}
												</Column>
												<Column isSize={8}>
													<h1 class="title">{selectedAppInfo.name}</h1>
													<h2 class="subtitle">App ID: {selectedAppInfo.id}</h2>	
													<div className='buttons'>
														<Button isColor='primary' onClick={this.changeAppName}>Change App Name</Button>
														<Button isColor='primary' isInverted href="https://dev.capitual.com" target="_blank">Documentation</Button>
													</div>
												</Column>
											</Columns>
										</CardContent>
									</Card>

									<br />

									<Card>
										<CardHeader>
											<CardHeaderTitle>Author Information</CardHeaderTitle>
										</CardHeader>
										<CardContent>
											<div class="control">
												<label>Author Name</label>
												<input class="input is-small" type="text" value={this.state.authorName} onChange={e => this.setState({ authorName: e.target.value })} />
											</div>
											<br/>
											<div class="control">
												<label>Author Email</label>
												<input class="input is-small" type="text" value={this.state.authorEmail} onChange={e => this.setState({ authorEmail: e.target.value })} />
											</div>
											<br/>
											<div class="control">
												<label>Author Phone</label>
												<input class="input is-small" type="text" placeholder="+1" value={this.state.authorPhone} onChange={e => this.setState({ authorPhone: e.target.value })} />
											</div>
										</CardContent>
										<CardFooter>
											<Button isColor='primary' isOutlined onClick={() => this.saveInfo('author')}>Save</Button>
										</CardFooter>
									</Card>

									<br />

									<Card>
										<CardHeader>
											<CardHeaderTitle>Legal</CardHeaderTitle>
										</CardHeader>
										<CardContent>
											<div class="control">
												<label>Terms of Use</label>
												<input class="input is-small" type="text" placeholder="https://" value={this.state.tosUrl} onChange={e => this.setState({ tosUrl: e.target.value })} />
											</div>
											<br/>
											<div class="control">
												<label>Privacy Policy</label>
												<input class="input is-small" type="text" placeholder="https://" value={this.state.ppUrl} onChange={e => this.setState({ ppUrl: e.target.value })} />
											</div>
										</CardContent>
										<CardFooter>
											<Button isColor='primary' isOutlined onClick={() => this.saveInfo('legal')}>Save</Button>
										</CardFooter>
									</Card>

									<br />

									<Card>
										<CardHeader>
											<CardHeaderTitle>App Keys</CardHeaderTitle>
										</CardHeader>
										<CardContent>
											<div class="control">
												<label>Public Key</label>
												<input class="input is-small" type="text" value={selectedAppInfo.public_key} readonly />
											</div>
											<br/>
											<div class="control">
												<label>Private Key {" "}
												<a href="javascript:void(0)" onClick={() => this.revealPrivKey()}>
													<FontAwesomeIcon icon={["fal", this.state.revealedPrivKeys[selectedAppInfo.id] ? "eye-slash" : "eye"]} />
												</a>
												</label>
												<input class="input is-small" type="text" value={this.state.revealedPrivKeys[selectedAppInfo.id] || "\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF"} readonly />
											</div>
										</CardContent>
									</Card>
								</div>
								}

								{ this.state.selectedOption === 'domains' &&
								<div className='section'>
									<Card>
										<CardHeader>
											<CardHeaderTitle>App Keys</CardHeaderTitle>
										</CardHeader>
										<CardContent>
											Add your application's domains in order to be able to get access tokens from your users.
											<br/>
											<table class="table is-fullwidth is-hoverable">
												<tr>
													<th>Domain <a className='button is-link is-small is-rounded' onClick={this.addDomain}><FontAwesomeIcon icon={["fal", "plus"]} /></a></th>
													<th>Status <a className='button is-link is-inverted is-small is-rounded' onClick={this.getAppDomains}><FontAwesomeIcon icon={["fal", "sync"]} /></a></th>
												</tr>
												{ this.state.appDomains.map(item => (
													<tr>
														<td>{item.domain}</td>
														<td>
															{item.validated === 1 && <span class="tag is-success">Validated</span> }
															{item.validated === 0 && <span class="tag is-warning">Pending</span> }
															{item.validated === -1 && <span class="tag is-danger">Invalid</span> }

															{ item.validated != 1 &&
																<a className='button is-small is-primary' style={{ marginLeft: '20px' }} onClick={() => this.confirmDomain(item)}>Confirm</a>
															}

															<a style={{ marginLeft: '20px' }} className='button is-small is-danger is-rounded' onClick={() => this.removeDomain(item.domain)}>
																<FontAwesomeIcon icon={["fal", "trash-alt"]} />
															</a>
														</td>
													</tr>
												))}
											</table>
										</CardContent>
									</Card>
								</div>
								}

								{ this.state.selectedOption === 'generateApiKey' &&
								<div className='section'>

								</div>
								}

								{ this.state.selectedOption === 'maintenance' &&
								<div className='section'>
									<Card>
										<CardHeader>
											<CardHeaderTitle>Revoke Private Key</CardHeaderTitle>
										</CardHeader>
										<CardContent>
											<p>If your app's private key has been leaked or stolen, use this button to revoke the previous private key and create a new one.</p>
											<p><b>Warning:</b> Do not forget to update the private key on your app code, otherwise it might stop working.</p>
											<br/>
											<Button isColor='warning' onClick={this.revokePrivKey}>Revoke Private Key</Button>
										</CardContent>
									</Card>
									<br/>
									<Card>
										<CardHeader>
											<CardHeaderTitle>Delete App</CardHeaderTitle>
										</CardHeader>
										<CardContent>
											<p><b>This action is irreversible.</b></p>
											<br/>
											<Button isColor='danger' onClick={this.removeApp}>Delete this app</Button>
										</CardContent>
									</Card>
								</div>
								}
							</React.Fragment>
						}
					</Column>
					}

					{ !this.state.selectedApp &&
					<Column isSize={9}>
						<Hero>
							<HeroBody>
								<center>
									<img src={devNoApps} style={{ maxWidth: '300px' }} />
								</center>
							</HeroBody>
						</Hero>
					</Column>
					}
				</Columns>

				<Footer />
			</div>
		)
	}
}

export default Developers