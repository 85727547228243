import React from 'react';
import T from 'i18n-react'
import alertify from 'alertify.js'
import { Link } from 'react-router-dom';
import currencyjs from 'currency.js'
import QRCode from 'qrcode.react';

import WalletSelector from './WalletSelector';
import CryptoSelector from './CryptoSelector';
import WalletDisplay from './WalletDisplay'
import { AskPin, DefPinProc } from './PinInput'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import cap from '../lib/capsdk'

import { Button } from 'bloomer'; 

class PaymentArea extends React.Component {

	state = {
		cryptowallet: "",
		cryptoCurrency: {},
		deductFrom: {},
		valueInCrypto: false
	}

	setCurr = val => {
		this.setState({
			cryptoCurrency: val,
			valueInCrypto: false
		}, () => [this.getCryptoWallet(), this.forecastCryptoValue()])
	}

	getCryptoWallet = () => {
		if (!this.state.cryptoCurrency.id) return;
		this.setState({ 
			cryptowallet: ""
		})
		cap.requestA('currencies.getCryptoWallet', {
			sess_key: cap.sess_key || null,
			crypto: this.state.cryptoCurrency.id,
			taken_by_type: 'invoice',
			taken_by: this.props.invoice.code
		}).then(cryptowallet => this.setState({ cryptowallet }))
	}

	componentWillReceiveProps = () => {
		this.forecastCryptoValue()
	}

	forecastCryptoValue = () => {
		if (!this.state.cryptoCurrency.id) return;

		cap.requestA('currencies.currencyExchange', {
			from_currency: this.props.invoice.currency.iso_code,
			to_currency: this.state.cryptoCurrency.iso_code,
			amount: String(this.props.invoice.amount),
			senderPays: true
		}).then(valueInCrypto => this.setState({ valueInCrypto: currencyjs(valueInCrypto, {...this.state.cryptoCurrency, formatWithSymbol: true }).format() }))
	}

	forecastValue = async () => {
		this.setState({
			payButtonLoading: true
		})

		try {
			let walletPositionInInvoiceCurrency = await cap.requestA('currencies.currencyExchange', {
				from_currency: this.state.deductFrom.currency.iso_code,
				to_currency: this.props.invoice.currency.iso_code,
				amount: String(this.state.deductFrom.funds),
				senderPays: false
			})

			if (parseFloat(walletPositionInInvoiceCurrency) < parseFloat(this.props.invoice.amount)) {
				this.setState({
					insufficientError: true,
					payButtonDisabled: true,
					payButtonLoading: false
				})
			} else {
				this.setState({
					insufficientError: false,
					payButtonDisabled: false,
					payButtonLoading: false
				})
			}
		} catch (e) {
			if (e === 'InvalidExchange')
				alertify.okBtn(T.translate("global.labels.ok")).alert(T.translate("modals.moneyExchange.deniedError"))

			this.setState({ payButtonLoading: false })
		}
	}

	payWithCapitual = async() => {
		try {
			this.setState({
				payButtonLoading: true
			})
			let pin = await AskPin(T.translate("pages.pay.payInvoice"))
			let txId = await cap.requestA('invoices.payInvoice', {
				invoice_id: this.props.invoice.code,
				from_wallet: this.state.deductFrom.wallet_address,
				pin
			})
			this.setState({
				payButtonLoading: false,
				successTxid: txId
			}, () => this.props.onPay())
		} catch (e) {
			if (e === 'InvalidExchange')
				alertify.okBtn(T.translate("global.labels.ok")).alert(T.translate("modals.moneyExchange.deniedError"))
			else if (e === 'UnderLimit')
				alertify.okBtn(T.translate("global.labels.ok")).alert(T.translate("pages.pay.underLimit"))
			else
				DefPinProc(e)

			this.setState({
				payButtonLoading: false
			})
		}
	}

	componentDidMount() {
		this.setCurr(0);
	}

	render() {
		if(this.props.method==='capitual') {
			if(cap.sess_key) {
				return (
					<div className="column is-10">
						<WalletSelector
	                        label={ T.translate("pages.pay.deductFrom") }
	                        style={{ height: "200px" }}
	                        name="wallet"
	                        onWalletSelect={deductFrom => this.setState({ deductFrom }, this.forecastValue)}
	                        position="up"
	                        selected={this.state.deductFrom}
	                        required
	                      />
	                      { this.state.insufficientError &&
	                      	<p class="has-text-danger"><br/>{ T.translate("pages.pay.insufficientBalance") }</p>
	                      }
						<br/><br/>
						<Button className="is-large is-fullwidth is-rounded" onClick={this.payWithCapitual} isLoading={this.state.payButtonLoading} isColor={this.state.payButtonSuccess ? 'success' : 'primary'} isStatic={this.state.payButtonDisabled || !this.state.deductFrom.id}>{ T.translate("pages.pay.submit") }</Button>
					</div>
				)
			} else {
				return (
					<p>
						<Link to={"/login?continue="+encodeURIComponent("/pay/"+this.props.invoice.code)}>{ T.translate("pages.pay.signIn") }</Link> { T.translate("pages.pay.signIn2") }
						<br/><br/>
					</p>
				)
			}
		} else {
			return (
				<div className="column is-10">
					<CryptoSelector selected={this.state.cryptoCurrency} onChange={this.setCurr} />

					<br/>
					<br/>
					<div className="image has-text-centered">
						<QRCode value={(this.state.cryptoCurrency.name ? this.state.cryptoCurrency.name.toLowerCase()+':' : '')+this.state.cryptowallet+"?amount="+String(this.state.valueInCrypto).replace(/[^0-9\.]/g, '')} />
						<br/>
						{ this.state.valueInCrypto ? this.state.valueInCrypto : <FontAwesomeIcon
					        className='super-crazy-colors'
					        name='rocket'
					        size='2x'
					        spin
					        style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
					      /> }
					</div>
					<br/>

					<div>
						<WalletDisplay invoice={this.props.invoice}  value={this.state.cryptowallet} />
					</div>
					<br/>
				</div>
			)
		}
	}
}

export default PaymentArea;