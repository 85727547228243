import React, { Fragment } from 'react';
import T from 'i18n-react'
import {NavLink as Link, Redirect} from "react-router-dom";
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import Avatar from '../components/Avatar';
import alertify from 'alertify.js'
import uaparser from 'ua-parser-js'

import cap from '../lib/capsdk'
import userdataService from '../services/userdataService'

import {
    Navbar,
    NavbarItem,
    NavbarStart,
    NavbarEnd,
    NavbarBrand,
    NavbarDropdown,
    NavbarBurger,
    NavbarMenu,
    NavbarDivider,
    Container,
    Notification,
    Column,
    Columns,
    Button,
    Title,
    Subtitle
} from "bloomer";

import '../assets/styles/top-bar-menu.css';
import 'bulma-extensions/bulma-badge/bulma-badge.min.css'

import brand from '../assets/images/SVG/capitual-brand.svg';
import avatarDefault from '../assets/images/SVG/avatar-default.svg';
import svgPending from '../assets/images/SVG/welcome.svg'
import svgAnalysis from '../assets/images/SVG/analysis.svg'
import svgDenied from '../assets/images/SVG/denied.svg'
import svgCompliance from '../assets/images/SVG/compliance.svg'
import svgEmail from '../assets/images/SVG/confirm_email.svg'
import svgPhone from '../assets/images/SVG/confirm_phone.svg'
import svgMedal from '../assets/images/SVG/medal.svg'

const USER_STATUS = {
    "-2": "LOCKED",
    "-1": "AWAITING",
    "0": "PENDING",
    "1": "CONFIRMED"
}

class TopNavBar extends React.Component {

    state = {
        isActive: false,
        newOrganizationModal: false,
        newUserModal: false,
        newInvoiceModal: false,
        isLogged: false,
        isBiz: false,
        isPending: false,
        isAnalyzing: false,
        isDenied: false,
        isCompliance: false,
        avatar: avatarDefault,
        primaryConf: false,
        needConfEmail: false,
        needConfPhone: false,
        modals: {
            newWallet: false,
            quickPayment: false,
            exchange: false,
            newContact: false
        }
    }

    constructor(props) {
        super(props)
    }

    componentWillMount = () => {
      let isLogged = cap.sess_key
      if (isLogged) {
        this.setState({
            isLogged
        })
        this.getTopBarData()
      }
    }

    getTopBarData = async() => {

        cap.requestC('members.listPhone', {}, 60).then(phones => {
            let unconfirmedPrimaryPhone = Array.isArray(phones) && phones.filter(item => !parseInt(item.confirmed) && parseInt(item.primary))
            if (phones.length && unconfirmedPrimaryPhone && unconfirmedPrimaryPhone.length) {
                this.setState({
                    needConfPhone: true,
                    primaryConf: true,
                    phoneNum: unconfirmedPrimaryPhone[0].phonenumber
                })
            }
        })

        cap.requestC('members.listEmail', {}, 60).then(emails => {
            let unconfirmedPrimaryEmail = Array.isArray(emails) && emails.filter(item => !parseInt(item.confirmed) && parseInt(item.primary))
            if (emails.length && unconfirmedPrimaryEmail && unconfirmedPrimaryEmail.length) {
                this.setState({
                    needConfEmail: true,
                    primaryConf: true,
                    emailDomain: emails[0].email.split("@")[1],
                    emailLogin: 'http://'+emails[0].email.split("@")[1],
                    emailAddr: unconfirmedPrimaryEmail[0].email
                })
            }
        })

        cap.requestC('members.profileget', {}, 60).then(profData => {
            this.setState({
                profData,
                hasFilledProfile: (profData.name && profData.birthday && profData.gender) ? true : false
            })
        }).then(() => {
            cap.requestA('kyc.getAccountStatus', {}, 30).then(checkStatus => {
                let opts = {}
                checkStatus = parseInt(checkStatus)
                switch (checkStatus) {
                    case 0:
                        opts.isPending = true
                        break
                    case -1:
                        opts.isAnalyzing = true
                        break
                    case -2:
                        opts.isDenied = true
                        break
                    case -3:
                        opts.isCompliance = true
                        break
                    default:

                }
                this.setState(opts)
            })
        })

        userdataService.all().then(udata => {
            this.setState({
                avatar: udata.avatar || avatarDefault
            })

            window.$_Tawk = window.$_Tawk || {}
            window.$_Tawk.onLoad = function() {
                let ua = new uaparser()
                window.$_Tawk.visitor = {
                    name: udata.name,
                    email: udata.email
                }
                window.$_Tawk.setAttributes({
                  phone: udata.phone,
                  "userId": "CAP-U"+udata.id,
                  "userAgent": this.parseUA(),
                  "resolution": (window.screen.width * window.devicePixelRatio) + "x" + (window.screen.height * window.devicePixelRatio),
                  "session": cap.sess_key,
                  "status": USER_STATUS[udata.status]
                })
            }
        })
    }

    parseUA = () => {
        let ua = window.navigator.userAgent
        if (ua === '{5A1A5112-F92C-4EE6-B1F5-2336643B9513}')
          return "Capitual App "+T.translate("pages.settings.sessions.browserOnOs")+" Android"
        else if (ua === '{9CE36BDF-FDB6-4FB4-AD35-2368F840FF03}')
          return "Capitual App "+T.translate("pages.settings.sessions.browserOnOs")+" iOS"
        else {
          let all = uaparser(ua);
          return (
            all.browser.name +
            " " +
            all.browser.version +
            " on " +
            all.os.name +
            " " +
            (all.os.version ? all.os.version+" " : "") +
            (all.device.vendor ? "(" + all.device.vendor + ")" : "")
          );
        }
      };


    styles = {
        icon: {
            color: '#76838F'
        }
    }

    logout = () => {
      alertify.okBtn(T.translate("components.navbar.yesLogout")).cancelBtn(T.translate("global.labels.cancel")).confirm(T.translate("components.navbar.confirmLogout"), () => {
          cap.requestA('push.removeDevice')
          cap.logout()
          alertify.success(T.translate("components.navbar.loggedOut"))
          this.setState({shouldRedir: 'https://www.capitual.com'})
      });
    }

    onClickNav = () => this.setState({
        isActive: !this.state.isActive,
        mobileActiveMenuItem: undefined
    })

    chooseActiveItem = item => this.setState({ mobileActiveMenuItem: (this.state.mobileActiveMenuItem === item ? undefined : item) })

    toggleNewOrganization = () => this.setState({
        newOrganizationModal: !this.state.newOrganizationModal
    })
    toggleNewUser = () => this.setState({
        newUserModal: !this.state.newUserModal
    })
    toggleNewInvoice = () => this.setState({
        newInvoiceModal: !this.state.newInvoiceModal
    })
    toggleRedeemEvoucher = () => this.setState({
        redeemEVoucherModal: !this.state.newInvoiceModal
    })

    render() {

        if (this.state.shouldRedir) {
          window.location.href = this.state.shouldRedir
        }

        return (
            <React.Fragment>
                { window.location.host.includes("testnet") &&
                    <Notification isColor='danger top-alert'>
                        { T.translate("components.navbar.testnetWarning.text") } <a href="https://help.capitual.com/kb0202006" target="_blank">{ T.translate("components.navbar.testnetWarning.link") }</a>
                    </Notification>
                }

                <Notification isColor='dark top-alert'>
                    <Container>
                        <Columns className='no-border is-mobile'>
                            <Column isSize={9}>
                                <Subtitle>Baixe o App do Capitual para ter acesso a toda a experiência da CapConta!</Subtitle>
                            </Column>
                            <Column className='no-border' isSize={3} hasTextAlign='right'>
                                <Columns>
                                    <Column>
                                        <a href="https://play.google.com/store/apps/details?id=com.capitual">
                                            <img style={{ width: '150px' }} src={T.translate("pages.settings.kyc.mobile.badge.android")} />
                                        </a>
                                    </Column>
                                    <Column style={{ paddingTop: '20px' }}>
                                        <a href="https://apps.apple.com/br/app/capitual/id1458793819">
                                            <img style={{ width: '135px' }} src={T.translate("pages.settings.kyc.mobile.badge.ios")} />
                                        </a>
                                    </Column>
                                </Columns>
                            </Column>
                        </Columns>
                    </Container>
                </Notification>

                <Navbar id="top-bar-menu" className="is-white">

                    <Container>

                        <NavbarBrand>

                            <NavbarBurger isActive={this.state.isActive} onClick={this.onClickNav}/> 
                            
                            <a className="navbar-item" href="https://www.capitual.com">
                                <img
                                    src={brand}
                                    alt="Capitual"
                                    width={140}
                                    height="40"/>
                            </a>
                        </NavbarBrand>

                        <NavbarMenu isActive={this.state.isActive} isHidden={this.props.isMenuHidden}>
                            { this.state.isActive && <div class='navbar-menu-overlay is-hidden-tablet animated fadeIn' /> }

                            {this.state.isLogged
                                ? (
                                    <Fragment>
                                        <NavbarEnd>
                                            <NavbarItem hasDropdown isHoverable>
                                                <NavbarItem onClick={() => this.chooseActiveItem(-1) }>
                                                    <Avatar isSize="32x32" url={this.state.avatar} />
                                                </NavbarItem>
                                                <NavbarDropdown  className={"is-bottom is-right "+(this.state.mobileActiveMenuItem === -1 && 'is-active')} isBoxed>
                                                    <a href="javascript:void(0)" onClick={this.logout} className="navbar-item">
                                                        <FontAwesomeIcon icon={['fal', 'sign-out']} className="has-margin-right is-2x" />
                                                        { T.translate("components.navbar.menu.logout") }
                                                    </a>
                                                </NavbarDropdown>
                                            </NavbarItem>
                                        </NavbarEnd>
                                    </Fragment>

                            ):(

                                <NavbarEnd>
                                    <NavbarItem hasDropdown isHoverable>
                                    </NavbarItem>
                                </NavbarEnd>)}
                        </NavbarMenu>
                    </Container>
                    
                </Navbar>
            </React.Fragment>
        )
    }
}

export default TopNavBar;
