import React from 'react';
import T from 'i18n-react'
import { Hero, HeroBody, HeroHeader, Container, Title, Button } from "bloomer";
import { Link } from 'react-router-dom'
import Svg from 'react-inlinesvg';
import TopNavBar from "../components/TopNavBar";
import Footer from '../components/Footer'
import illustration from '../assets/images/SVG/404.svg'

const NotFound = (props, context) => {
    return [

        <Hero isFullHeight>
            <HeroHeader>
                <TopNavBar />
            </HeroHeader>
            <HeroBody>
                <Container hasTextAlign="centered">
                    <div style={{
                        width: '30%',
                        margin: '0 auto'
                    }}>
                        <Svg height={300} src={illustration} className="animated-draw is-running is-2x"  />
                    </div>
                    <br/>
                    <br/>

                    <h2 className="title has-text-weight-light has-text-black has-margin-top is-2x">{ T.translate('pages.404.h1') }</h2>
                    <div className="subtitle has-text-gray-light has-margin-top">
                        { T.translate("pages.404.text") }{" "}
                        <a href="https://www.capitual.com" className="has-text-primary has-margin-left is-underlined">{ T.translate("pages.404.link") }</a>
                    </div>

                </Container>
            </HeroBody>
        </Hero>,
        <Footer />
    ]
}

export default NotFound;
