/* DEPRECATED */

import _ from 'lodash'
import globalStorage from 'react-global-storage'
import { sprintf } from 'sprintf-js'

class i18n {

	constructor(ulang = false) {

		this.langs = require('../i18n/strings.json')

		let savedLang = localStorage.getItem('locale')
		if (savedLang && !ulang) {
			this.ulang = savedLang
		} else {
			if (ulang && this.langs.hasOwnProperty(ulang))
				this.ulang = ulang
			else {
				window.navigator.languages.every(lang => {
					if (this.langs.hasOwnProperty(lang)) {
						this.ulang = lang
						return false
					}
				})

				if (!this.ulang)
					this.ulang = this.getFallbackLanguage()
			}
		}

		this.languagesList = []
		for (let lang of Object.values(this.langs)) {
			this.languagesList.push({
				language: lang.language,
				country: lang.country,
				iso_639_1: lang.iso_639_1,
				iso_3166_1: lang.iso_3166_1
			})
		}
	}

	setLanguage(ulang) {
		if (ulang && this.langs.hasOwnProperty(ulang)) {
			this.ulang = ulang
			localStorage.setItem('locale', this.ulang)
		}
		else
			throw new Error("Invalid language", ulang)
	}

	getLanguage() {
		return this.ulang
	}

	getLanguageData() {
		return {
			language: this.langs[this.ulang].language,
			country: this.langs[this.ulang].country,
			iso_639_1: this.langs[this.ulang].iso_639_1,
			iso_3166_1: this.langs[this.ulang].iso_3166_1
		}
	}

	getLanguages() {
		return this.languagesList
	}

	getFallbackLanguage() {
		return 'en-US'
	}

	__(key, args = {}) {
		let translate = _.get(this.langs[this.ulang], key, key)
		return sprintf(translate, args)
	}

	__p(num, if1, if2) {
		return num===1 ? if1 : if2
	}
}

let instance = new i18n()

let __ = (key, args) => instance.__(key, args)

export default instance

export { __ }