import Moment from 'moment'
import {setLanguage, detectLanguage} from './helpers/i18n'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import 'animate.css'
import './assets/styles/main.css';
import Login from './views/Login'
import ServiceLogin from './views/ServiceLogin'
import Developers from './views/Developers'
import CapPay from './views/CapPay'
import Receipt from './views/Receipt'
import Transaction from './views/Transaction'
import NotFound from './views/NotFound'

// i18n
setLanguage(detectLanguage())

// Moment localize
Moment.locale(detectLanguage())

function App() {
  return (
    <main>
			<BrowserRouter>
				<Switch>
					<Route exact path="/login" component={Login}/>
					<Route exact path="/servicelogin/:service" component={ServiceLogin} />
					<Route exact path="/apps/mine" component={Developers} />
					<Route exact path="/pay/:id" component={CapPay}/>
					<Route exact path="/transaction/:id.pdf" component={Receipt}/>
					<Route exact path="/transaction/:id" component={Transaction}/>
					<Route exact path="/tx/:id.pdf" component={Receipt} />
					<Route exact path="/tx/:id" component={Transaction}/>
					<Route component={NotFound}/>
				</Switch>
			</BrowserRouter>
    </main>
  );
}

export default App;
