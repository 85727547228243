import React from "react";
import T from 'i18n-react'
import ReactDOM from 'react-dom'
import currencyjs from "currency.js";
import walletsService from "../services/walletsService";
import { Link } from "react-router-dom";

import PerfectScrollbar from 'react-perfect-scrollbar'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import cap from '../lib/capsdk'

import '../assets/styles/wallet-selector.css'

import 'react-perfect-scrollbar/dist/css/styles.css'

import {
  Dropdown,
  DropdownContent,
  DropdownTrigger,
  DropdownMenu,
  DropdownDivider,
  DropdownItem,
  Button,
  Icon
} from "bloomer";

class WalletSelector extends React.Component {
  state = {
    isActive: false,
    selected: null,
    wallets: []
  };

  constructor(props) {
    super(props);
    if (this.props.selected) {
      this.setState({
        selected: this.props.selected
      });
    }
    if (this.props.selected) {
      this.setState({
        selected: this.props.selected
      });
    }
  }

  componentWillMount() {
      document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = (e) => {
      let thenode = ReactDOM.findDOMNode(this)
      if (thenode.contains(e.target)) return;
      this.setState({ isActive: false })
  }

  componentDidMount = async () => {
    if (!cap.sess_key) return;
    this.setState({
      wallets: await walletsService.all()
    });
    if (this.props.selected) {
      this.setState({
        selected: this.props.selected
      });
    }
  };

  componentWillReceiveProps = nextProps => {
    this.setState({
      selected: nextProps.selected
    });

    walletsService.all().then(wallets => this.setState({ wallets }))
  };

  toggleMenu = () => this.setState({ isActive: !this.state.isActive });

  onSelectWallet = wallet => {
    this.setState({
      selected: wallet,
      isActive: false
    });
    if (this.props.onWalletSelect) this.props.onWalletSelect(wallet);
  };

  render() {
    const { isActive } = this.state;
    const { toggleMenu, onSelectWallet } = this;
    return (
      <Dropdown
        isActive={isActive}
        isFullWidth={!this.props.isCompact}
        className={`wallet-selector-dropdown is-${this.props.position} ${this.props.invertTextColor && "invert-text-color"} `}
      >
        <DropdownTrigger onClick={toggleMenu} isFullWidth={!this.props.isCompact}>
          <Button
            className={`${this.props.isColor ? "is-"+this.props.isColor : "is-primary"} ${this.props.isSize ? "is-"+this.props.isSize : "is-medium"} is-outlined ${
              this.props.isRounded ? "is-rounded" : ""
            }`}
            isOutlined={!this.props.noBorder}
            isInverted={this.props.noBorder}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            isFullWidth={!this.props.isCompact}
            style={{ color: this.props.noBorder ? (this.props.invertTextColor ? '#000' : '#A1ABB3') : null, justifyContent: this.props.isLeft ? 'flex-start' : 'center'}}
          >
            {this.state.selected && this.state.selected.wallet_name ? [
              this.state.selected.wallet_name,
              <span className="wallets-dropdown-icon">
                <FontAwesomeIcon icon={["fal", "angle-down"]} />
              </span>
            ] : [
              (this.props.label ? this.props.label : T.translate("components.walletSelector.placeHolder")),
              <span className="wallets-dropdown-icon">
                <FontAwesomeIcon icon={["fal", "angle-down"]} />
              </span>
            ]}
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          className={'animated fadeIn'+(this.props.position === 'up' ? 'Up' : 'Down')}
          style={{
            height:
              this.props.style && this.props.style.height
                ? this.props.style.height
                : "200px"
          }}
        >
          <DropdownContent
            style={{
              height:
                this.props.style && this.props.style.height
                  ? this.props.style.height
                  : "200px"
            }}
          >
            <PerfectScrollbar>
              {this.state.wallets && this.state.wallets.length && this.state.wallets.map(w => (
                <DropdownItem href="javascript:void(0);">
                  <div onClick={() => onSelectWallet(w)}>
                    <b>{w.wallet_name}</b> <small>{w.currency.name}</small>
                    <br />
                    <p>
                      {" "}
                      <span className="has-text-primary">
                        {currencyjs(w.funds, {
                          formatWithSymbol: true,
                          ...w.currency
                        }).format()}
                      </span>{" "}
                    </p>
                  </div>
                </DropdownItem>
              ))}
              <DropdownDivider />
              <Link to="/wallets">
                <DropdownItem>
                  <b>{ T.translate("components.walletSelector.manageWallets") }</b>
                </DropdownItem>
              </Link>
            </PerfectScrollbar>
            
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default WalletSelector;
