import React, { Component } from 'react';

import CapSDK from '@jesobreira/capsdk'

import globalStorage from 'react-global-storage';
import { withRouter } from 'react-router'
import AdvQueue from 'advqueue'

import md5 from 'md5'
import store from 'store2'
import moment from 'moment'
import Fingerprint from 'fingerprintjs'

let httpConfig = {
	"ws": "https://api.capitual.com",
	"rest": "https://api.capitual.com"
}

let capitual = new CapSDK(httpConfig.ws, ['rest'])
let cache = store.session.namespace('api_cache')

Object.defineProperty(capitual, 'sess_key', {
  get: () => localStorage.sess_key || sessionStorage.sess_key || globalStorage.state.sess_key
});

let fingerprint = String(new Fingerprint({canvas: true}).get())

// create resource pool
const queue = new AdvQueue()
queue.processingTimeout = 90
queue.concurrency = 10

queue.onProcessingTimeout= ({ id, name, priority, resolve, reject, createdAt, fn, rawFn, startedAt }) => {
    // wait 3s and retry
    console.warn("Request failed, trying after 3s: "+name)
    setTimeout(() => resolve(queue.add(rawFn, priority, name)), 3000)
}

capitual.requestA = async(path, args = {}, priority = 0) => {
	args.device = fingerprint
    if (capitual.sess_key)
        args.sess_key = capitual.sess_key

    if (process.env.NODE_ENV === 'staging' && !args.requestInitiated)
    	args._requestInitiated = { by: window.location.href, at: new Date().toLocaleString() }

    try {
    	let result
    	do {
			result = await queue.add(() => capitual.request(path, args), priority, JSON.stringify({ path, args, ref: window.location.href }))
		} while (result === undefined)

		if (result) {
			let cache_key = md5(JSON.stringify(args))
			let cache_namespace = cache.namespace(md5(path))
			cache_namespace(cache_key, {
				createdAt: moment().unix(),
				result
			})
		}

		if (window.document.body.classList.contains('socket-timeout'))
			window.document.body.classList.remove('socket-timeout')

		return result
	} catch(e) {
		console.log(e + " on " + path + " call with args " + JSON.stringify(args))
		if (e.toString().startsWith("TimeoutError")) {
			//window.document.body.classList.add('socket-timeout')
			//window.location.reload(true)
			return capitual.requestA(path, args, priority)
		}
		if (e.code === 'Forbidden') {
	      console.log("Token is invalid")
	      capitual.logout()
	      return window.location.href = "/login?continue="+encodeURIComponent(window.location.pathname+window.location.search);
	    }
	    
	    throw e;
	}
}
capitual.requestC = async(path, args = {}, maxTime = 3*60) => {
	args.device = fingerprint
    if (capitual.sess_key)
        args.sess_key = capitual.sess_key
	let cache_namespace = cache.namespace(md5(path))
	let cache_key = md5(JSON.stringify(args))

	if (cache_namespace.has(cache_key) && maxTime) {
		let cache_content = cache_namespace(cache_key)
		if (cache_content.createdAt + maxTime >= moment().unix()) {
			return cache_content.result
		} else {
            cache_namespace.remove(cache_key)
            return capitual.requestC(path, args, maxTime)
        }
	}

	let result
	do {
		result = await capitual.requestA(path, args)
	} while (result === undefined)

	return result
}

capitual.requestF = (path, args = {}) => {
	return new Promise((resolve, reject) => {
		let formData = new FormData()

		args.device = fingerprint
	    if (capitual.sess_key)
	        args.sess_key = capitual.sess_key

		for (let key in args) {
			if (!key.endsWith("B64"))
				formData.append(key, args[key])
			else {
				formData.append(key.substr(0, key.length-3), dataURItoBlob(args[key]))
			}
		}

		queue.add(() => fetch(httpConfig.rest + '/v1.0/' + path.split(".").join("/"), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'multipart/form-data'
			},
			body: formData
		})).then(res => res.json())
		.then(res => {
			console.log(res)
		}).catch(err => {
			console.log(err)
		})
	})
}

capitual.clear = (namespace = false) => {
	if (namespace)
		cache.namespace(md5(namespace)).clearAll()
	else
		cache(false)
}

capitual.logout = () => {
	capitual.request('members.logout', { sess_key: capitual.sess_key })
	globalStorage.setState('sess_key', '')
	localStorage.removeItem('sess_key')
	sessionStorage.removeItem('sess_key')
	capitual.clear()
}

let RequireLogin = class extends Component {

	sessCheck = async() => {
		try {
			let test = await capitual.requestA('session.isValid', { sess_key: capitual.sess_key })

			if (window.document.body.classList.contains('socket-timeout'))
				window.document.body.classList.remove('socket-timeout')

			if (!test) {
				capitual.logout()
				this.props.history.push("/login?continue="+encodeURIComponent(window.location.pathname+window.location.search));
			}
		} catch(e) {
			window.document.body.classList.add('socket-timeout')
			setTimeout(this.sessCheck, 5000)
		}
	}
	render() {
		if (window.location.pathname === "/login") return null
			
		if (!capitual.sess_key)
			this.props.history.push("/login?continue="+encodeURIComponent(window.location.pathname+window.location.search));
		else
			this.sessCheck()

		return ""
	}
}

let Redir = class extends Component {
	render() {
		if(this.props.to)
			this.props.history.push(this.props.to)
		return null
	}
}

function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}

RequireLogin = withRouter(RequireLogin)
Redir = withRouter(Redir)

export default capitual;

export { RequireLogin, Redir }
